<template>
  <section
    v-if="relatedTrainings.length > 0"
    class="training-details-related-trainings container"
  >
    <div class="training-details-related-trainings__inner">
      <h3 class="training-details-related-trainings__title title">
        {{ relatedTrainingsTitle }}
      </h3>

      <ul class="training-details-related-trainings__list">
        <li
          v-for="(training, index) in relatedTrainings"
          :key="index"
          class="training-details-related-trainings__list-item"
        >
          <a
            :href="`/${training.uri}`"
            class="training-details-related-trainings__card"
          >
            <div class="training-details-related-trainings__card-inner">
              <span
                v-if="training.type"
                class="training-details-related-trainings__type"
              >{{
                training.type
              }}</span>

              <div class="training-details-related-trainings__card-details">
                <h4
                  v-if="training.title"
                  class="training-details-related-trainings__reviews-title"
                >
                  {{ training.title }}
                </h4>

                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-if="training.description"
                  class="training-details-related-trainings__description"
                  v-html="training.description"
                />
                <!--eslint-enable-->

                <div class="training-details-related-trainings__rating">
                  <rating-indicator
                    v-if="training.rating"
                    :value="training.rating"
                  />

                  <span
                    v-if="training.reviewsAmount !== 0"
                    class="training-details-related-trainings__reviews-counter"
                  >({{ training.reviewsAmount }})</span>
                </div>
              </div>

              <a
                :href="`/${training.uri}`"
                class="button--primary"
              > Meer informatie </a>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "TrainingDetailsRelatedTrainings",
  props: {
    relatedTrainingsTitle: {
      type: String,
      default: "Gerelateerde trainingen"
    },
    currentTrainingId: {
      type: String,
      required: true
    },
    allTrainings: {
      type: Array,
      required: true
    },
    relationType: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      relatedTrainings: []
    };
  },
  mounted() {
    this.getRelatedTrainings();
  },
  methods: {
    getRelatedTrainings() {
      const LIMIT = 4;

      // Remove current entry from passed 'allTrainings' prop
      const trainingArray = this.$props.allTrainings.filter( (training) => { return training.id !== this.$props.currentTrainingId });
      // If passed 'allTrainings' array is smaller or equal to 'LIMIT', return 'allTrainings'
      if ( trainingArray.length <= LIMIT) {
        this.relatedTrainings =  trainingArray;
        return;
      }
      // Filter 'allTrainings' array list on relationType
      this.relatedTrainings = trainingArray.filter((training, index) => {
        if (index >= LIMIT) return false;
        return training.type === this.$props.relationType;
      });
      // if no related training can be found, take the first training that is not this one
      if (this.relatedTrainings.length === 0) {
        this.relatedTrainings = [this.getFirstTraining()];
      }
    },
    getFirstTraining() {
      return this.$props.allTrainings.find(training => {
        return training.id !== this.$props.currentTrainingId;
      });
    }
  }
};
</script>
