<template>
  <div class="quiz__questions-container">
    <div
      ref="background"
      class="quiz__background"
    />

    <div class="quiz__questions">
      <the-modal>
        <template #toggle-content>
          <div class="quiz__legend">
            <span>{{ ui.legend }}</span>
            <i class="far fa-question-circle" />
          </div>
        </template>

        <template #modal-content>
          <h3 class="quiz__legend-title">
            <i class="far fa-question-circle" />
            {{ ui.legend }}
          </h3>
          <ul class="quiz__legend-answers">
            <li
              v-for="answer in answers"
              :key="answer.label"
            >
              <dl class="quiz__legend-definitions">
                <dt>{{ answer.label }}</dt>
                <dd>{{ answer.explanation }}</dd>
              </dl>
            </li>
          </ul>
        </template>
      </the-modal>

      <div class="quiz__questions-inner">
        <span class="quiz__step-counter">{{ stepCounter }}</span>

        <span class="quiz__question">{{ currentQuestion.question }}</span>
      </div>
    </div>
    <div class="quiz__answers-wrapper">
      <ol class="quiz__answers">
        <li
          v-for="answer in answers"
          :key="answer.id"
          class="quiz__answer"
        >
          <button
            class="quiz__button-answer"
            :class="{ 'quiz__button-answer--is-selected': answerIsSelected(answer) }"
            @click="answerClickHandler(answer)"
          >
            {{ answer.label }}
          </button>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import TheModal from "./TheModal.vue";

export default {
  name: "TheQuizQuestionnaire",
  components: {
    TheModal
  },
  props: {
    questionnaireCopy: {
      required: true,
      type: Object
    },
    questions: {
      required: true,
      type: Array
    },
    answers: {
      required: true,
      type: Array
    },
    currentStep: {
      required: true,
      type: Number
    },
    savedAnswer: {
      required: false,
      type: Object,
      default: null
    }
  },
  emits: ['onAnswerClickHandler'],
  data() {
    return {
      ui: {
        legend: "Legenda"
      }
    };
  },
  computed: {
    stepCounter() {
      return `${this.currentStep}/${this.questions.length}`;
    },
    currentQuestion() {
      return this.questions[this.currentStep - 1];
    }
  },
  methods: {
    answerIsSelected(answer) {
      if (!this.savedAnswer) return;
      return answer.label === this.savedAnswer.userInput.label;
    },
    emitAnsweredQuestion(answer) {
      const answeredQuestion = {
        id: this.currentStep,
        question: this.currentQuestion,
        userInput: answer
      };

      this.$emit("onAnswerClickHandler", answeredQuestion);
    },
    animateBackground() {
      const background = this.$refs.background;

      background.style.animationName = "none";

      requestAnimationFrame(() => {
        setTimeout(() => {
          background.style.animationName = "";
        }, 0);
      });
    },
    answerClickHandler(answer) {
      this.emitAnsweredQuestion(answer);
      this.animateBackground();
    }
  }
};
</script>
