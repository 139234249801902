<template>
  <div class="container--share">
    <div class="share-buttons">
      <span class="share-buttons__title">Share</span>
      <a
        class="share-buttons__button share-buttons__button--facebook"
        :href="faceBookLink"
        target="_blank"
        @click.prevent="openFacebookModal()"
      />
      <a
        class="share-buttons__button share-buttons__button--linkedIn"
        :href="linkedInLink"
        target="_blank"
        @click.prevent="openLinkedInModal()"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ShareButtons",
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    faceBookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.url
      )}&picture=${encodeURIComponent(this.image)}&title=${encodeURIComponent(
        this.title
      )}&description=${encodeURIComponent(this.description)}`;
    },
    linkedInLink() {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        this.url
      )}&title=${encodeURIComponent(this.title)}&summary=${encodeURIComponent(this.description)}`;
    }
  },
  methods: {
    getMetaContentByName(key, identity, dataType) {
      const content = dataType === null ? "content" : dataType;
      return document.querySelector(`meta[${key}='${identity}']`).getAttribute(content);
    },
    openFacebookModal() {
      window.open(
        this.faceBookLink,
        "targetWindow",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
      );
      return false;
    },
    openLinkedInModal() {
      window.open(
        this.linkedInLink,
        "targetWindow",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
      );
      return false;
    }
  }
};
</script>
