<template>
  <button
    class="card-filter"
    :class="active ? 'card-filter--active' : null"
    @click="setFilter()"
  >
    {{ filter }}
  </button>
</template>

<script>
export default {
  name: "CardFilter",
  props: {
    filter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    setFilter() {
      const element = event.target;
      const parent = element.closest(".np-container");
      //Select only the filters that are within this block
      const filters = parent.getElementsByClassName("card-filter");
      this.active = false;

      if (!element.className.includes("card-filter--active")) this.active = true;

      for (let i = 0; i < filters.length; i++) {
        filters[i].classList.remove("card-filter--active");
      }

      //Get all cards
      const cards = parent.getElementsByClassName("card");
      for (let i = 0; i < cards.length; i++) {
        //Make sure all cards are shown
        cards[i].style.display = "flex";

        //If the filter is set to active
        if (this.active) {
          //Set class
          element.classList.add("card-filter--active");
          //Get their filter(s)
          const cardClass = cards[i].className.replace("card", "");
          //Hide all cards without that filter
          if (!cardClass.includes(this.filter)) cards[i].style.display = "none";
        }
      }
    }
  }
};
</script>
