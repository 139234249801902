<template>
  <modal-wrapper :is-open="open">
    <template #default="{ closeModal }">
      <slot :close-modal="closeModal" />
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "./ModalWrapper.vue";

export default {
  name: "ModalOnce",
  components: { ModalWrapper },
  props: {
    storageKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  beforeMount() {
    if (!this.storageKey) {
      this.open = true;
      return;
    }
    this.open = !JSON.parse(localStorage.getItem(this.storageKey));
    localStorage.setItem(this.storageKey, "true");
  }
};
</script>
