<template>
  <div class="col-start-1 col-span-14 flex flex-col md:grid md:grid-cols-block-grid lg:gap-y-2 gap-x-4 lg:gap-x-6">
    <!-- Logos -->
    <div class="flex col-start-1 col-span-14 gap-2 lg:gap-6">
      <div class="relative mx-auto max-w-[90rem]">
        <span class="absolute top-[-2px] left-[-2px] bottom-[-2px] w-8 lg:w-20 bg-fade-to-left z-[1]" />
        <div
          ref="carousel"
          :data-slider-id="sliderId"
          class="glide cursor-grab" 
          :class="`glide--${id}`"
        >
          <div
            class="glide__track"
            data-glide-el="track"
          >
            <ul class="glide__slides">
              <li
                v-for="(logo, loopIndex) in logoCount"
                :key="`slide--${loopIndex}`" 
                :date-slide="loopIndex"
                :date-glide-index="loopIndex"
                class="glide__slide max-w-[7.5625rem] lg:max-w-[12rem] pb-4"
              >
                <button 
                  :date-glide-index="loopIndex"
                  class="relative w-full h-[4.125rem] lg:h-[6.5625rem] pb-[0.375rem] lg:pb-2"
                >
                  <div class="relative block w-full h-full bg-[--neutrals-white]">
                    <slot
                      :name="`logo-${id}--${loopIndex}`"
                      :date-index="loopIndex"
                    />
                    <svg
                      class="hidden absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[0.5rem] logo-tile__shadow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="78"
                      height="18" 
                      viewBox="0 0 78 18" 
                      fill="none"
                    >
                      <path
                        d="M39 18L0.894882 0L77.1051 0L39 18Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <span class="absolute top-[-2px] right-[-2px] bottom-[-2px] w-8 lg:w-20 bg-fade-to-right z-[1]" />
      </div>
    </div>

    <!-- Featured -->
    <div class="flex flex-col col-start-2 col-span-12">
      <div
        v-for="(logo, loopIndex) in logoCount"
        :key="`featured--${loopIndex}`"
        :class="[active === loopIndex ? 'block' : 'hidden']"
      >
        <slot :name="`featured-${id}--${loopIndex}`" />
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import dataLayerEventService from "../../dataLayerEventService.js";
import ViewportHelpers from "../../helpers/ViewportHelpers.js";

export default {
  name: "TwLogoCases",
  props: {
    id: {
      type: Number,
      required: true
    },
    logos: {
      type: Array,
      default() {
        return [];
      }
    },
    sliderSettings: {
      type: Object,
      default: () => ({
        showBulltest: true,
        showControls: true
      }),
    },
    sliderId: {
      type: String,
      required: true
    },
    sliderProps: {
      type: Object,
      default: () => ({
        // See https://glidejs.com/docs/options/ for all availible options
      })
    }
  },
  data() {
    return {
      type: null,
      active: 0,
      glide: null,
      focusAt: 0,
      peek: { before: 0, after: 0},
      autoplay: null,
      gridColumns: null,
      gap: 16,
      perView: 1,
      glide__slides: [],
      loading: false
    };
  },
  computed:{
    logoCount(){
      return this.logos.length
    }
  },
  beforeUnmount() {
    this.glide.destroy();
  },
  beforeMount () {
    const { type, startAt, perView, focusAt,gap, autoplay, hoverpause,keyboard,bound, swipeThreshold, dragThreshold, perTouch, touchRatio, touchAngle, animationDuration, rewind, rewindDuration, animationTimingFunc, direction, peek, breakpoints, classes, throttle } = this.sliderProps;
    this.type = type ?? "carousel";
    const defaultClasses = {
      direction: {
        ltr: "glide--ltr",
        rtl: "glide--rtl"
      },
      slider: "glide--slider",
      carousel: "glide--carousel",
      swipeable: "glide--swipeable",
      dragging: "glide--dragging",
      cloneSlide: "glide__slide--clone",
      activeNav: "glide__bullet--active",
      activeSlide: "glide__slide--active",
      disabledArrow: "glide__arrow--disabled"
    };

    this.glide = new Glide(`.glide--${this.id}`, {
      type: this.type,
      startAt: startAt ?? 0,
      perView: perView ?? 1,
      focusAt: focusAt ?? this.focusAt,
      gap: gap ?? this.gap,
      autoplay: autoplay ?? false,
      hoverpause: hoverpause ?? true,
      keyboard: keyboard ?? true,
      bound: bound ?? false,
      swipeThreshold: swipeThreshold ?? 80,
      dragThreshold: dragThreshold ?? 120,
      perTouch: perTouch ?? false,
      touchRatio: touchRatio ?? 0.5,
      touchAngle: touchAngle ?? 45,
      animationDuration: animationDuration ?? 400,
      rewind: rewind ?? true,
      rewindDuration: rewindDuration ?? 800,
      animationTimingFunc: animationTimingFunc ?? "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
      direction: direction ?? "ltr",
      peek: peek ?? this.peek,
      breakpoints: breakpoints ?? {},
      classes: classes ?? defaultClasses,
      throttle: throttle ?? 25,
    });

    
  },
  mounted() {
    this.glide.mount();
    this.updateGlide();
    
    this.addActiveStates(this.active);
    this.glide.on("run", this.glideRunHandler);
    this.glide.on("resize", this.updateGlide);
  },
  methods: {
    inViewPort() {
      return ViewportHelpers.checkIfElementIsInViewportWindow(this.$refs["carousel"]);
    },
    getCurrentQuote() {
      return this.getQuote(this.glide.index);
    },
    getQuote(index) {
      const el = document.querySelector(`div[data-glide-featured-el='featured-${this.id}--${index}'] blockquote`);

      if (!el) {
        return null;
      }

      return el.innerText;
    },
    pushDataLayerEvent(index) {
      if(index >= 0 && this.inViewPort() && !this.loading) {
        const quote = this.getQuote(index);

        dataLayerEventService.pushSliderEvent({
          eventName: "business_case_viewed",
          sliderId: quote || this.sliderId,
          slideIndex: index
        });
      }
    },
    updateGlide() {
      this.calculateCustomView();
      this.setCustomClickWatchers();
      this.setCtaClickWatchers();
    },
    setCustomClickWatchers() {
      const self = this;
      this.glide__slides = document.querySelectorAll(`.glide--${this.id} .glide__slide`);
      this.glide__slides.forEach(slide => {
        slide.addEventListener("click", function() {
          self.slideDirectionForIndex(slide);
        });
      });
    },
    setCtaClickWatchers() {
      const ctaList = document.querySelectorAll(`div[data-glide-featured-el^='featured-${this.id}'] a`);

      ctaList.forEach(ctaItem => {
        const self = this;
        ctaItem.addEventListener("click", function() {
          self.handleCtaClick(ctaItem);
        });
      });
    },
    handleCtaClick(ctaItem) {
      const quote = this.getCurrentQuote();

      dataLayerEventService.pushSliderCtaClick({
        eventName: "click_business_case",
        sliderId: quote || this.sliderId,
        slideIndex: this.glide.index,
        ctaItem
      });
    },
    slideDirectionForIndex(slide) {
      const clickedIndex = slide.getAttribute("date-glide-index");
      const isClone = slide.classList.contains("glide__slide--clone");

      if (isClone) {
        this.glide.go(this.active < clickedIndex ? "<" : ">");
      } else {
        this.glide.go(clickedIndex < this.active ? "<" : ">");
      }
    },
    glideRunHandler() {
      this.setActiveFeaturedContent(this.glide.index);
      this.addActiveStates(this.glide.index);
    },
    calculateCustomView() {
      this.loading = true;
      const glideWidth = document.querySelector(`.glide--${this.id}`).offsetWidth;
      const maxSlideWidth = window.innerWidth > 1024 ? 192 : 121;
      const correction = window.innerWidth > 1024 ? 6 : 10;
      this.gap = window.innerWidth > 1024 ? 40 : 25;
      this.perView = glideWidth / ((maxSlideWidth + this.gap) - correction);
      
      this.glide.update({ 
        perView: this.perView,
        gap: this.gap,
        focusAt: "center"
      });
      this.loading = false;
    },
    addActiveStates(glideIndex) {
      const slideButtons = document.querySelectorAll(`.glide--${this.id} .glide__slide button`);
      slideButtons.forEach(slide => {
        if(Number(slide.getAttribute("date-glide-index")) === glideIndex) {
          this.addClass(slide, "bg-[--neutrals-white");
          this.addClass(slide, "logo--active");
          this.addClass(slide, "logo-tile__shadow");
          this.addClass(slide, "grayscale-0");
          this.removeClass(slide, "grayscale");
        } else {
          this.removeClass(slide, "bg-[--neutrals-white");
          this.removeClass(slide, "logo--active");
          this.removeClass(slide, "logo-tile__shadow");
          this.removeClass(slide, "grayscale-0");
          this.addClass(slide, "grayscale");
        }
      });
    },
    addClass(item, className) {
      if (!item.classList.contains(className)) {
        item.classList.add(className)
      }
    },
    removeClass(item, className) {
      if (item.classList.contains(className)) {
        item.classList.remove(className)
      }
    },
    setActiveFeaturedContent(i) {
      this.active = Number(i);
      this.pushDataLayerEvent( i );
    }
  }
};
</script>
