<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 1.99984H12.6663V0.666504H11.333V1.99984H4.66634V0.666504H3.33301V1.99984H2.66634C1.93301 1.99984 1.33301 2.59984 1.33301 3.33317V13.9998C1.33301 14.7332 1.93301 15.3332 2.66634 15.3332H13.333C14.0663 15.3332 14.6663 14.7332 14.6663 13.9998V3.33317C14.6663 2.59984 14.0663 1.99984 13.333 1.99984ZM13.333 13.9998H2.66634V5.33317H13.333V13.9998Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon",
  props: {
    fill: {
      type: String,
      default: "#000"
    }
  }
};
</script>
