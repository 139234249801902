import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.VUE_APP_ALGOLIASEARCH_APPLICATION_ID,
  process.env.VUE_APP_ALGOLIASEARCH_API_KEY_SEARCH
);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    return algoliaClient.search(requests);
  }
};

export default searchClient;
