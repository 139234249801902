<template>
  <nav
    class="nav-main"
    :class="[classes, !open ? 'nav-main--closed' : 'nav-main--open']"
  >
    <slot />
    <NavigationButton />
  </nav>
</template>

<script>
import EventBus from "./../event-bus";
import NavigationButton from "./NavigationButton.vue";

export default {
  name: "MainNavigation",
  components: {
    NavigationButton
  },
  props: {
    classes: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: false,
      scrollPos: 0,
      highestScrollPos: 0,
      lowestScrollPos: 0,
      hidden: false
    };
  },
  created() {
    this.setScroll();
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
    });
  },
  methods: {
    setScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      if (window.scrollY > this.scrollPos) {
        //Scrolling down
        if (window.scrollY - this.highestScrollPos > 50) {
          //Als de gebruiker naar beneden heeft gescrolled: hide het menu.
          this.hidden = true;
        }

        //Reset lowest scroll position
        this.lowestScrollPos = 0;
      } else {
        //Scrolling up
        if (window.scrollY > this.lowestScrollPos) {
          this.lowestScrollPos = window.scrollY;
        }
        if (this.lowestScrollPos - window.scrollY > 100) {
          //Als de gebruiker 100px naar boven heeft gescrolled: show het menu.
          this.hidden = false;
        }

        //Reset highest scroll position
        this.highestScrollPos = 0;
        this.highestScrollPos = window.scrollY;
      }
      this.scrollPos = window.scrollY;

      if (window.scrollY < 50) {
        this.hidden = false;
      }

      if (!this.hidden) {
        document.body.classList.add("menu-visible");
      } else {
        document.body.classList.remove("menu-visible");
      }
    }
  }
};
</script>
