<template>
  <button
    class="video-modal__trigger"
    @click="onModal()"
  >
    <slot />
  </button>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "VideoModalTrigger",
  props: {
    video: {
      type: String,
      required: true
    }
  },
  computed: {
    videoUrl() {
      const div = document.createElement("div");
      div.innerHTML = JSON.parse(this.video);
      const video = div.querySelector("iframe");
      return video.src.split("?")[0];
    }
  },
  methods: {
    onModal() {
      EventBus.$emit("openVideoModal", {
        video: this.videoUrl
      });
    }
  }
};
</script>
