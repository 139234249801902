<template>
  <div
    v-if="open"
    class="instructions-popup"
  >
    <div
      class="instructions-popup__background"
      @click="onClose"
    />
    <div class="instructions-popup__content">
      <img
        v-if="image"
        :src="image"
        class="instructions-popup__image"
      >
      <div class="instructions-popup__text">
        <h3>{{ title }}</h3>
        <p>{{ content }}</p>
        <button
          class="button--primary"
          @click="onClose"
        >
          Ok, ik snap het
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "InstructionsPopup",
  data() {
    return {
      open: false,
      image: null,
      title: null,
      content: null
    };
  },
  mounted() {
    EventBus.$on("openInstructionsPopup", data => {
      this.open = true;
      this.image = data.image;
      this.title = data.title;
      this.content = data.content;
      document.body.classList.add("menu-open");
    });
  },
  methods: {
    onClose() {
      this.open = false;
      document.body.classList.remove("menu-open");
    }
  }
};
</script>
