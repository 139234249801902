<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-12 gap-6"
    :class="alignItems"
  >
    <div
      :class="[
        COLUMN_STYLES[ratio].left, 
        {'hidden lg:block': hiddenTillBreakpoint === 'left'},
        {'order-2': reverseOrder || reverseOrderTillBreakpoint},
        {'lg:order-none': reverseOrderTillBreakpoint}
      ]"
    >
      <slot name="left" />
    </div>

    <div
      :class="[
        COLUMN_STYLES[ratio].right,
        {'hidden lg:block': hiddenTillBreakpoint === 'right'}
      ]"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>
const COLUMN_STYLES = {
  '1-1': {
    left: 'lg:col-span-6',
    right: 'lg:col-span-6'
  },
  '1-2': {
    left: 'lg:col-span-4',
    right: 'lg:col-span-8'
  },
  '2-1': {
    left: 'lg:col-span-8',
    right: 'lg:col-span-4'
  },
  '5-7': {
    left: 'lg:col-span-5',
    right: 'lg:col-span-7'
  },
  '7-5': {
    left: 'lg:col-span-7',
    right: 'lg:col-span-5'
  }
};

export default {
  props: {
    ratio: {
      type: String,
      required: false,
      default: '1-1',
      validator: (value) => value in COLUMN_STYLES
    },
    hiddenTillBreakpoint: {
      type: String,
      required: false,
      default: null,
      validator: (value => ['left', 'right'].includes(value))
    },
    alignItems: {
      type: String,
      required: false,
      default: 'items-start',
      validator: (value => ['items-start', 'items-center'].includes(value))
    },
    reverseOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    reverseOrderTillBreakpoint: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup() {
    return {
      COLUMN_STYLES
    };
  }
};
</script>
