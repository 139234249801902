<template>
  <ul
    v-if="items"
    class="trainings-overview-search__list"
  >
    <li
      v-for="(item, index) in items"
      :key="`training--${index}`"
    >
      <training-card
        :item="item"
        :is-dark="isDark"
        :icon-fill="iconFill"
      />
    </li>

    <li
      v-if="items.length === 0"
      class="trainings-overview-search__no-results"
    >
      <slot />
    </li>
  </ul>
</template>

<script>
import TrainingCard from "./TrainingCard.vue";

export default {
  name: "TrainingsCardsList",
  components: { TrainingCard },
  props: {
    items: {
      type: Array,
      required: true
    },
    isDark: {
      type: Boolean,
      default: false
    },
    iconFill: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.dataLayerPush(this.items);
  },
  methods: {
    dataLayerPush: items => {
      if (!items) {
        return;
      }

      const impressions = items.map((i, key) => ({
        name: i.title,
        id: i.objectID,
        price: i.discountPrice && i.discountPrice > 0 ? i.discountPrice : i.price,
        brand: "GITP",
        category: "Training",
        list: "Search Results",
        position: key
      }));

      const products = items.map((i, key) => ({
        item_id: i.objectID,
        item_name: i.title,
        currency: "EUR",
        discount: i.discountPrice && i.discountPrice > 0 ? i.price - i.discountPrice : 0,
        index: key,
        item_brand: "GITP",
        item_category: "Training",
        item_list_id: "trainingen",
        item_list_name: "Trainingen",
        price: i.discountPrice && i.discountPrice > 0 ? i.discountPrice : i.price,
        quantity: 1
      }));

      // Measures product impressions and also tracks a standard pageview for the tag configuration.
      // dataLayer push voor UA
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous
      window.dataLayer.push({
        event: "ua_view_item_list",
        ecommerce: {
          currencyCode: "EUR",
          impressions
        }
      });

      // dataLayer push voor GA4
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: products
        }
      });
    }
  }
};
</script>
