<template>
  <nav :class="['nav-fullscreen', open ? 'nav-fullscreen--open' : null]">
    <div class="nav-fullscreen__container">
      <div class="nav-fullscreen__columns">
        <NavigationFullscreenColumn
          :column="firstColumn"
          :classes="'nav-column--first'"
        />

        <NavigationFullscreenColumn
          v-for="(column, index) in restColumns"
          :key="index"
          :column="column"
        />
      </div>

      <div
        v-if="top.length"
        class="nav-fullscreen__top"
      >
        <a
          v-for="(item, i) in top"
          :key="i"
          :href="item.url"
        >{{ item.title }}</a>
      </div>

      <SearchBar
        classes="mobile"
        :placeholder="searchPlaceholder"
      />

      <div class="nav-fullscreen__bottom-wrapper">
        <div class="nav-fullscreen__bottom">
          <div
            v-if="bottomPrimary.length"
            class="nav-fullscreen__bottom-primary"
          >
            <a
              v-for="(item, i) in bottomPrimary"
              :key="i"
              :href="item.url"
            >{{ item.title }}</a>
          </div>

          <div
            v-if="bottomSecondary.length"
            class="nav-fullscreen__bottom-secondary"
          >
            <a
              v-for="(item, i) in bottomSecondary"
              :key="i"
              :href="item.url"
            >{{ item.title }}</a>
          </div>
        </div>

        <slot name="language-switcher" />

        <div class="nav-fullscreen__certification">
          <p>{{ isoText }}</p>
        </div>
      </div>
    </div>

    <slot name="lines" />
  </nav>
</template>

<script>
import EventBus from "./../event-bus";
import NavigationFullscreenColumn from "./NavigationFullscreenColumn.vue";
import SearchBar from "./SearchBar.vue";

export default {
  name: "NavigationFullscreen",
  components: {
    NavigationFullscreenColumn,
    SearchBar
  },
  props: {
    lang: {
      type: String,
      default: "nl"
    },
    siteUrl: {
      type: String,
      required: true
    },
    top: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    bottomPrimary: {
      type: Array,
      required: true
    },
    bottomSecondary: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      open: false,
      searchTerm: null
    };
  },
  computed: {
    searchPlaceholder() {
      if (this.lang === "en") {
        return "Search the site ...";
      }
      return "Doorzoek de site...";
    },
    isoText() {
      if (this.lang === "en") {
        return "GITP is ISO 9001:2015 and ISO 27001:2013 certified";
      }
      return "GITP is ISO 9001:2015 en ISO 27001:2013 gecertificeerd";
    },
    firstColumn() {
      return this.columns[0];
    },
    restColumns() {
      return this.columns.slice(1);
    }
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
    });
  },
  methods: {
    switchState() {
      this.open ^= true;
    },
    search($event) {
      window.location.href = "/search?q=" + $event.target.value;
    }
  }
};
</script>
