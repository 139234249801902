<template>
  <div class="bg-white shadow-[0px_2px_2px_0px_rgba(61,61,61,0.08)] h-[3rem] lg:h-[4.0625rem]">
    <!-- Mobile menu -->
    <TransitionRoot
      as="template"
      :show="menuOpen || searchOpen"
    >
      <Dialog
        as="div" 
        class="relative z-40 xl:hidden" 
        @close="closeAll()"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-0" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full flex-col overflow-y-auto bg-[--blue] shadow-xl"
            >
              <!-- Controls -->
              <div class="h-12 px-4 flex items-center justify-between xl:hidden bg-[--neutrals-white]">
                <!-- Logo -->
                <a
                  :href="homeLink.url"
                  :target="homeLink.target"
                >
                  <span class="sr-only">GITP</span>
                  <slot name="logo" />
                </a>
                
                <div class="flex gap-2">
                  <button
                    type="button"
                    class="px-2 w-9 h-9 flex flex-col justify-center items-center"
                    @click="closeAll()"
                  >
                    <span class="sr-only">Close window</span>
                    <i class="fa-solid fa-xmark" />
                  </button>
                </div>
              </div>
            
              <!-- Links -->
              <div
                v-if="menuOpen"
                class="
                  flex-1 flex flex-col
                  px-4 pt-4
                  color-[--neutrals-white]
                "
              >
                <Disclosure
                  v-for="(column, columnIndex) in navColumns"
                  :key="`column--${columnIndex}`"
                  v-slot="{ open }"
                  as="div"
                  class="
                    flex flex-col
                    w-full
                    border-b border-[--blue-b-250]
                  "
                >
                  <DisclosureButton class-name="px-4 flex items-center justify-between w-full">
                    <div
                      class="
                        flex flex-row gap-2 items-center justify-between
                        py-4
                        text-[--neutrals-white]
                        w-full
                    "
                    >
                      <p class="text-xl leading-6">
                        {{ column.title }}
                      </p>

                      <ChevronDownIcon :class="['transition-transform', {'rotate-180': open, 'rotate-0': !open}]" />
                    </div>
                  </DisclosureButton>

                  <DisclosurePanel class-name="flex flex-col gap-6">
                    <div
                      class="
                      flex flex-col gap-6
                      px-2
                    "
                    >
                      <ul
                        role="list"
                        class="flex flex-col gap-5"
                      >
                        <li 
                          v-for="(link, linkIndex) in [...column.col1, ...column.col2]" 
                          :key="`col__item--${linkIndex}`" 
                          class="flex"
                        >
                          <a
                            :href="link.url"
                            :target="link.target"
                            class="group text-base flex gap-2 items-center text-[--neutrals-white] hover:text-[--neutrals-white] focus:text-[--neutrals-white] "
                          >
                            <ArrowRightIcon />
                            {{ link.title }}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="column.ctas"
                      class="
                        flex flex-col gap-4
                        py-6
                    "
                    >
                      <link-element
                        v-for="(ctaItem, ctaIndex) in column.ctas"
                        :key="`col__cta--${ctaIndex}`"
                        :link="{
                          text: ctaItem.title || ctaItem.text,
                          url: ctaItem.url,
                          target: ctaItem.target,
                        }"
                        :is-full-width-mobile="true"
                        styling="secondary"
                      />
                    </div>
                  </DisclosurePanel>
                </Disclosure>

                <div
                  v-if="loginLink"
                  class="py-6"
                >
                  <a 
                    v-if="loginLink" 
                    :href="loginLink.url" 
                    :target="loginLink.target" 
                    class="
                      block group w-full
                      px-4 py-[0.6875rem]
                      bg-[currentColor]
                      border-[currentColor]
                      text-[--neutrals-white] focus:text-[--neutrals-white] border-[--blue-b-400] focus:border-[--blue-b-400]"
                  >
                    <div class="flex-row-reverse flex gap-2 items-center justify-center w-full text-[--blue-b-400] hover:text-[--blue-b-300] focus:text-[--blue-b-400]">
                      <span class="">{{ loginLink.text }}</span>
                    </div>
                  </a>
                </div>

                <div class="mt-auto flex flex-col items-center justify-center w-full p-4 border-solid border-[--blue-b-250] border-t-[1px]">
                  <LanguageSwitch
                    :languages="languages"
                    language-key="title"
                    color-hex="FFFFFF"
                    classes="text-[--white] lg:min-w-[3.75rem]"
                  />
                </div>
              </div>

              <!-- Search -->
              <div
                v-if="searchOpen"
                class="flex-1 flex flex-col gap-4 px-4 py-8 color-[--neutrals-white]"
              >
                <SearchForm :search-link="searchLink" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
        <!-- // eslint-disable-next-line -->
      </Dialog>
    </TransitionRoot>

    <div class="fixed z-[40] w-full">
      <nav
        aria-label="Top"
        class="relative"
      >
        <!-- navigation -->
        <div class="bg-white">
          <div class="block mx-0 px-4 xl:px-0 max-w-full">
            <div class="flex flex-col md:grid md:grid-cols-block-grid gap-y-4 lg:gap-y-6 gap-x-4 lg:gap-x-6">
              <div class="col-span-12 col-start-2 flex h-12 items-center justify-between gap-6 lg:h-[4.0625rem]">
                <!-- Logo -->
                <div class="hidden xl:flex xl:items-center">
                  <a
                    :href="homeLink.url"
                    :target="homeLink.target"
                  >
                    <span class="sr-only">GITP</span>
                    <slot name="logo" />
                  </a>
                </div>

                <!-- Desktop Menu -->
                <div class="hidden h-full xl:flex">
                  <!-- Mega menus -->
                  <PopoverGroup>
                    <div class="flex h-full justify-center items-center gap-10">
                      <TwNavigationPopover
                        v-for="({ title, description, ctas, col1, col2 }, columnIndex) in navColumns"
                        :key="`column--${columnIndex}`"
                        :title="title"
                        :description="description"
                        :ctas="ctas"
                        :col1="col1"
                        :col2="col2"
                      />
                    </div>
                  </PopoverGroup>
                </div>

                <!-- Mobile menu and search -->
                <div class="flex flex-1 items-center justify-between xl:hidden">
                  <!-- Logo -->
                  <a
                    :href="homeLink.url"
                    :target="homeLink.target"
                    class="xl:hidden"
                  >
                    <span class="sr-only">GITP</span>
                    <slot name="logo" />
                  </a>
                  
                  <div class="flex gap-2">
                    <button
                      type="button" 
                      class="px-2 w-9 h-9 flex flex-col justify-center items-center"
                      @click="openSearch()"
                    >
                      <span class="sr-only">Open Search</span>

                      <MagnifyingGlassIcon />
                    </button>

                    <button
                      type="button"
                      class="px-2 w-9 h-9 flex flex-col justify-center items-center"
                      @click="openMenu()"
                    >
                      <span class="sr-only">Open menu</span>
                      <i class="fa-solid fa-bars" />
                    </button>
                  </div>
                </div>

                <div class="hidden xl:flex flex-1 items-center justify-end gap-4">
                  <a
                    v-if="searchLink"
                    :href="searchLink.url"
                    :target="searchLink.target"
                    class="py-2 text-[--neutrals-n-500] hover:text-[--neutrals-n-400]"
                  >
                    <span class="sr-only">{{ searchLink.text }}</span>

                    <MagnifyingGlassIcon />
                  </a>

                  <LanguageSwitch
                    :languages="languages"
                    language-key="text"
                    color-hex="3d3d3d"
                    classes="text-[--neutrals-n-500] lg:min-w-[3.75rem]"
                  />

                  <a
                    v-if="loginLink"
                    :href="loginLink.url"
                    :target="loginLink.target"
                    class="
                      px-4 py-2
                      flex items-center justify-center
                      hover:bg-neutral-200
                      border rounded-sm border-solid border-[--neutrals-n-500]
                      text-sm text-[--neutrals-n-500] hover:text-[--neutrals-n-500] focus:text-[--neutrals-n-500] hover:no-underline
                      outline-none
                  "
                  >
                    <span>{{ loginLink.text }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-reserved-component-names */
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  PopoverGroup,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon";
import LanguageSwitch from '../ui/LanguageSwitch.vue';
import MagnifyingGlassIcon from "@/components/icons/MagnifyingGlassIcon";
import SearchForm from '../ui/SearchForm.vue';
import TwNavigationPopover from "@/components/tailwind/TwNavigationPopover";

export default {
  name: "TwNavigation",
  components: {
    ArrowRightIcon,
    ChevronDownIcon,
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    LanguageSwitch,
    MagnifyingGlassIcon,
    PopoverGroup,
    SearchForm,
    TransitionChild,
    TransitionRoot,
    TwNavigationPopover
  },
  props: {
    navColumns: {
      type: Array,
      required: true
    },
    homeLink: {
      type: Object,
      required: true
    },
    loginLink: {
      type: Object,
      required: true
    },
    searchLink: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menuOpen: false,
      searchOpen: false
    };
  },
  methods:{
    openSearch(){
      this.menuOpen = false;
      this.searchOpen = true;
    },
    openMenu(){
      this.menuOpen = true;
      this.searchOpen = false;
    },
    closeAll(){
      this.menuOpen = false;
      this.searchOpen = false;
    }
  }
};
</script>
