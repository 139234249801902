<template>
  <div
    v-if="filters && filters.length > 0"
    class="bg-[--blue-muted-bm-100] py-6 text-[1rem]"
  >
    <div class="flex flex-col md:grid md:grid-cols-block-grid gap-6">
      <div class="col-span-12 md:col-start-2 px-4 lg:px-0">
        <div class="flex items-center gap-6">
          <span class="uppercase max-sm:hidden">Filters</span>

          <div class="flex gap-4">
            <div
              v-for="(cat, i) of filters"
              :key="`filter-category--${i}`"
              class="select-container"
            >
              <vSelect
                v-model="catValue[i]"
                label="title"
                :clearable="false"
                :searchable="false"
                :options="cat"
                @update:model-value="selectChange"
              >
                <template #open-indicator="{ attributes }">
                  <ChevronDownIcon v-bind="attributes" />
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue"

export default {
  name: "TwArticleListFilters",
  components: { vSelect, ChevronDownIcon},
  props: {
    filters: {
      type: Array,
      default() {
        return [];
      }
    },
    defaults: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      catValue: []
    };
  },
  mounted() {
    this.catValue = this.filters.map((_, i) => this.defaults[i]);
  },
  methods: {
    selectChange(i) {
      window.location.href = i.url;
    }
  }
};
</script>

<style>
@import "vue-select/dist/vue-select.css";

:root {
  /* Borders */
  --vs-border-width: 0;

  /* Controls */
  --vs-controls-color: var(--blue-b-400);
  --vs-actions-padding: 0;

  /* Dropdown */
  --vs-dropdown-min-width: 300px;

  /* Selected */
  --vs-selected-color: var(--blue-b-400);
}

.select-container {
  position: relative;
}

.select-container:not(:last-child):after {
  content: "";
  width: 1px;
  height: 100%;
  background: var(--blue-muted-bm-400);
  position: absolute;
  top: 0;
  right: -8px;
}

.vs__dropdown-toggle,
.vs__selected,
.vs__search,
.vs__search:focus {
  padding: 0;
  margin: 0;
}

.vs__selected-options {
  padding: 0;
  gap: 8px;
}

.vs--single.vs--open .vs__selected {
  display: flex;
  position: relative;
}

.vs__dropdown-menu{
  margin-top: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 2px;
}
</style>
