<template>
  <div
    class="submenu-navigation"
    :class="{ visible: visible }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "SubmenuNavigation",
  data() {
    return {
      scrollPos: 0,
      highestScrollPos: 0,
      lowestScrollPos: 0,
      visible: false
    };
  },
  created() {
    this.setScroll();
  },
  methods: {
    setScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      if (window.scrollY > this.scrollPos) {
        //Scrolling down
        if (window.scrollY - this.highestScrollPos > 50) {
          //Als de gebruiker naar beneden heeft gescrolled: show het menu.
          this.visible = true;
        }

        //Reset lowest scroll position
        this.lowestScrollPos = 0;
      } else {
        //Scrolling up
        if (window.scrollY > this.lowestScrollPos) {
          this.lowestScrollPos = window.scrollY;
        }
        if (this.lowestScrollPos - window.scrollY > 100) {
          //Als de gebruiker 100px naar boven heeft gescrolled: hide het menu.
          this.visible = false;
        }

        //Reset highest scroll position
        this.highestScrollPos = 0;
        this.highestScrollPos = window.scrollY;
      }
      this.scrollPos = window.scrollY;

      if (window.scrollY < 50) {
        this.visible = false;
      }
    }
  }
};
</script>
