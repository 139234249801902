<template>
  <svg
    class="fill-current"
    width="24"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M5.293 9.793a1 1 0 0 1 1.414 0L12 15.086l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
