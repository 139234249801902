<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 5.99967H6V1.33301H4.66667V5.99967H3.33333V1.33301H2V5.99967C2 7.41301 3.10667 8.55967 4.5 8.64634V14.6663H6.16667V8.64634C7.56 8.55967 8.66667 7.41301 8.66667 5.99967V1.33301H7.33333V5.99967ZM10.6667 3.99967V9.33301H12.3333V14.6663H14V1.33301C12.16 1.33301 10.6667 2.82634 10.6667 3.99967Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "RestaurantIcon",
  props: {
    fill: {
      type: String,
      default: "#000"
    }
  }
};
</script>
