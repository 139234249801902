<template>
  <div
    v-if="languages && languages.length && currentLanguage && languageKey"
    class=""
  >
    <select
      v-model="currentLanguage" 
      class="w-max-content bg-transparent border-transparent outline-none border-0 focus:border-0 p-2 pr-7 tw-select focus:ring-0"
      :class="[`text-[#${colorHex}]`, colorHex === 'FFFFFF' ? ' tw-select--white' : 'tw-select--dark', classes]"
    >
      <option
        v-for="(language, i) of languages"
        :key="`language--${i}`"
        :value="language"
      >
        {{ language[`${languageKey}`] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitch",
  props: {
    languages: {
      type: Array,
      required: true
    },
    languageKey: {
      type: String,
      default: "title"
    },
    colorHex: {
      type: String,
      default: "FFFFFF"
    },
    classes: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentLanguage: null,
      backgroundImage: null
    };
  },
  watch: {
    currentLanguage() {
      if (!this.currentLanguage.current) {
        window.location.href = this.currentLanguage.url;
      }
    }
  },
  mounted() {
    if (this.languages && this.languages.length > 0) {
      const currentPage = this.languages.filter(e => e.current === true);
      this.currentLanguage = currentPage.length ? currentPage[0] : this.languages[0];
    }
  }
};
</script>
