<template>
  <section class="trainings-overview-search">
    <ais-instant-search
      class="trainings-overview-search__inner"
      :search-client="searchClient"
      :index-name="searchIndex"
      :routing="routing"
    >
      <Transition name="slide-fade">
        <div
          v-show="ui.filterMenuIsVisible"
          class="trainings-overview-search__filters-menu"
        >
          <div class="trainings-overview-search__filters">
            <div>
              <h3 class="trainings-overview-search__filters-menu-title">
                {{ ui.labels[locale].filterMenu }}
              </h3>

              <button
                type="button"
                class="trainings-overview-search__results-menu-filter-toggle trainings-overview-search__results-menu-filter-toggle--close"
                @click="filterButtonClickHandler"
              >
                <span>{{ ui.labels[locale].filterMenuClose }}</span>
                <i class="fas fa-times" />
              </button>
            </div>

            <div class="trainings-overview-search__filters-menu-actions">
              <ais-clear-refinements class="ais-ClearRefinements--mobile">
                <template #resetLabel>
                  {{ ui.labels[locale].resetFilters }}
                </template>
              </ais-clear-refinements>

              <button
                class="trainings-overview-search__results-menu-filter-toggle trainings-overview-search__results-menu-filter-toggle--mobile"
                @click="filterButtonClickHandler"
              >
                {{ ui.labels[locale].filterMenuShowResults }}
              </button>
            </div>

            <!-- Zoeken => Search -->
            <ais-search-box placeholder="Zoek" />

            <!-- Sorteeropties => Select -->
            <label class="trainings-overview-search__form-label">{{
              ui.labels[locale].sortingTitle
            }}</label>
            <div class="trainings-overview-search__form-field-divider" />
            <div class="trainings-overview-search__select">
              <ais-sort-by
                :items="[
                  { value: `${searchIndex}`, label: ui.labels[locale].sorting.popularity },
                  { value: `${searchIndex}--sortByTitleAsc`, label: ui.labels[locale].sorting.titleAsc },
                  {
                    value: `${searchIndex}--sortByTitleDesc`,
                    label: ui.labels[locale].sorting.titleDesc
                  },
                  { value: `${searchIndex}--sortByPriceAsc`, label: ui.labels[locale].sorting.priceAsc },
                  { value: `${searchIndex}--sortByPriceDesc`, label: ui.labels[locale].sorting.priceDesc }
                ]"
              />
              <span class="fas fa-chevron-down" />
            </div>


            <!-- Thema's => Checkboxes -->
            <label class="trainings-overview-search__form-label">{{ ui.labels[locale].themes }}</label>
            <div class="trainings-overview-search__form-field-divider" />
            <ais-refinement-list
              attribute="themes.title"
              :sort-by="['name:asc']"
              operator="or"
            />

            <!-- Budget => Slider-->
            <label class="trainings-overview-search__form-label">{{ ui.labels.price }}</label>
            <div class="trainings-overview-search__form-field-divider" />
            <ais-range-input attribute="price">
              <template #default="{ range, refine }">
                <div class="trainings-overview-search__range-input">
                  <div>€{{ (minValue || range.min) }}</div>
                  <vue-slider
                    :min="range.min"
                    :max="range.max"
                    :lazy="false"
                    interval="1"
                    :tooltip="'active'"
                    :tooltip-placement="['bottom']"
                    @change="val => {
                      maxValue = val
                      refine({ min: 0, max: val })
                    }"
                  />
                  <div>€{{ (maxValue || range.max) }}</div>
                </div>
              </template>
            </ais-range-input>

            <!-- Type => Select -->
            <label class="trainings-overview-search__form-label">{{ ui.labels[locale].type }}</label>
            <div class="trainings-overview-search__form-field-divider" />
            <div class="trainings-overview-search__select">
              <ais-menu-select 
                :attribute="`entryType`"
                :sort-by="['name:asc']"
              >
                <template #defaultOption>
                  {{ ui.labels[locale].allOptions }}
                </template>
              </ais-menu-select>
              <span class="fas fa-chevron-down" />
            </div>

            <!-- Duur training => Checkboxes -->
            <label class="trainings-overview-search__form-label">{{ ui.labels[locale].duration }}</label>
            <div class="trainings-overview-search__form-field-divider" />
            <ais-refinement-list
              attribute="trainingDuration"
              :sort-by="['name:asc']"
              operator="or"
            />
          </div>
        </div>
      </Transition>

      <div class="trainings-overview-search__results">
        <!-- Header with filter/sorting options -->
        <div class="trainings-overview-search__results-header">
          <div class="trainings-overview-search__results-menu">
            <button
              class="trainings-overview-search__results-menu-filter-toggle trainings-overview-search__results-menu-filter-toggle--mobile"
              @click="filterButtonClickHandler"
            >
              {{ ui.labels[locale].filterMenu }}
              <i class="fa-solid fa-sliders" />
            </button>

            <div class="trainings-overview-search__results-counter">
              <ais-stats>
                <template #default="{ nbHits }">
                  {{ nbHits }} Resultaten
                </template>
              </ais-stats>
            </div>

            <div class="trainings-overview-search__results-sort-and-clear">
              <ais-clear-refinements>
                <template #resetLabel>
                  {{ ui.labels[locale].resetFilters }}
                </template>
              </ais-clear-refinements>
            </div>
          </div>
        </div>

        <!-- Popular Trainings Content -->
        <div
          v-if="popularTrainings.length > 0"
          class="trainings-overview-search__popular-trainings"
        >
          <button
            type="button"
            class="trainings-overview-search__button-popular-training"
            @click="popularTrainingsButtonClickHandler"
          >
            <span
              class="trainings-overview-search__popular-training-label trainings-overview-search__popular-training-label--mobile"
            >{{ mobileTitle }}</span>
            <span
              class="trainings-overview-search__popular-training-label trainings-overview-search__popular-training-label--desktop"
            >{{ desktopTitle }}</span>
            <i
              class="fas fa-chevron-down popular-training-icon"
              :class="{ 'popular-training-icon--is-open': ui.popularTrainingsDropdownIsVisible }"
            />
          </button>

          <trainings-cards-list
            v-if="ui.popularTrainingsDropdownIsVisible"
            :items="popularTrainings"
            :is-dark="true"
            icon-fill="#D7E9E8"
          >
            {{ ui.labels[locale].noResults }}
          </trainings-cards-list>
        </div>

        <!-- Search results -->
        <ais-hits>
          <template #default="{ items }">
            <trainings-cards-list
              :items="items"
              icon-fill="#7E9492"
            >
              <div class="trainings-overview-search__no-results">
                <img
                  class="trainings-overview-search__no-results-icon"
                  src="/assets/icons/search-off.svg"
                  alt=""
                >
                <h3 class="trainings-overview-search__no-results-label">
                  {{ ui.labels[locale].noResults }}
                </h3>
                <p>{{ ui.labels[locale].noResultsDescription }}</p>
              </div>
            </trainings-cards-list>
          </template>
        </ais-hits>

        <!-- Search pagination -->
        <ais-pagination :padding="1">
          <template
            #default="{
              currentRefinement,
              nbPages,
              pages,
              isFirstPage,
              isLastPage,
              refine,
              createURL
            }"
          >
            <div class="algolia-pagination">
              <ul class="algolia-pagination__list">
                <template v-if="nbPages > 1">
                  <li
                    v-if="!isFirstPage"
                    class="algolia-pagination__slot algolia-pagination__slot--previous"
                  >
                    <a
                      class="algolia-pagination__link"
                      :href="createURL(currentRefinement - 1)"
                      @click.prevent="
                        refine(currentRefinement - 1);
                        scrollUp();
                      "
                    >
                      <span
                        class="algolia-pagination__arrow algolia-pagination__arrow--left"
                      />
                    </a>
                  </li>
                  <li
                    v-if="currentRefinement >= 2"
                    :class="{ 'algolia-pagination__slot--disabled': isFirstPage }"
                    class="algolia-pagination__slot algolia-pagination__slot--first"
                  >
                    <a
                      class="algolia-pagination__link"
                      :href="createURL(0)"
                      @click.prevent="
                        refine(0);
                        scrollUp();
                      "
                    >
                      {{ 1 }}
                    </a>
                  </li>
                  <li
                    v-if="currentRefinement >= 2"
                    :class="{ 'algolia-pagination__slot--disabled': isFirstPage }"
                    class="algolia-pagination__slot algolia-pagination__slot--first dots"
                  >
                    <a
                      class="algolia-pagination__link algolia-pagination__link dots"
                      :href="createURL(0)"
                      @click.prevent="
                        refine(0);
                        scrollUp();
                      "
                    >
                      ...
                    </a>
                  </li>
                  <li
                    v-for="page in pages"
                    :key="page"
                    class="algolia-pagination__slot"
                    :class="{
                      'algolia-pagination__slot--active': page === currentRefinement,
                      'algoliapagination__slot--active-close':
                        page + 1 === currentRefinement || page - 1 === currentRefinement
                    }"
                  >
                    <a
                      class="algolia-pagination__link"
                      :href="createURL(page)"
                      @click.prevent="
                        refine(page);
                        scrollUp();
                      "
                    >
                      {{ page + 1 }}
                    </a>
                  </li>

                  <li
                    v-if="currentRefinement < nbPages - 2"
                    :class="{ 'algolia-pagination__slot--disabled': isLastPage }"
                    class="algolia-pagination__slot algolia-pagination__slot--last dots"
                  >
                    <a
                      class="algolia-pagination__link algolia-pagination__link dots"
                      :href="createURL(nbPages)"
                      @click.prevent="
                        refine(nbPages);
                        scrollUp();
                      "
                    >
                      ...
                    </a>
                  </li>
                  <li
                    v-if="currentRefinement < nbPages - 2"
                    :class="{ 'algolia-pagination__slot--disabled': isLastPage }"
                    class="algolia-pagination__slot algolia-pagination__slot--last"
                  >
                    <a
                      class="algolia-pagination__link"
                      :href="createURL(nbPages)"
                      @click.prevent="
                        refine(nbPages);
                        scrollUp();
                      "
                    >
                      {{ nbPages }}
                    </a>
                  </li>
                  <li
                    v-if="!isLastPage"
                    class="algolia-pagination__slot algolia-pagination__slot--next"
                  >
                    <a
                      class="algolia-pagination__link"
                      :href="createURL(currentRefinement)"
                      @click.prevent="
                        refine(currentRefinement + 1);
                        scrollUp();
                      "
                    >
                      <span
                        class="algolia-pagination__arrow algolia-pagination__arrow--right"
                      />
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </template>
        </ais-pagination>
      </div>
    </ais-instant-search>
  </section>
</template>

<script>
import {
  AisInstantSearch,
  AisSearchBox,
  AisSortBy,
  AisMenuSelect,
  AisRangeInput,
  AisHits,
  AisRefinementList,
  AisPagination,
  AisStats,
  AisClearRefinements
} from "vue-instantsearch/vue3/es";

import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";
import VueSlider from "vue-slider-component";
import searchClient from "../algoliaSearchClient";
import TrainingsCardsList from "./TrainingsCardsList.vue";

const MEDIA_QUERY = "1024px";

export default {
  name: "TrainingOverviewSearch",
  components: {
    AisInstantSearch,
    AisSearchBox,
    AisSortBy,
    AisMenuSelect,
    AisRangeInput,
    VueSlider,
    AisStats,
    AisHits,
    AisRefinementList,
    AisClearRefinements,
    AisPagination,
    TrainingsCardsList
  },
  props: {
    locale: {
      type: String,
      default: "nl-NL"
    },
    searchIndex: {
      type: String,
      required: true
    },
    popularTrainings: {
      type: Array,
      default: () => []
    },
    mobileTitle: {
      type: String,
      required: true
    },
    desktopTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ui: {
        labels: {
          "nl-NL" : {
            themes: "Thema's",
            type: "Type",
            allOptions: "Alle",
            resetFilters: "Filters resetten",
            noResults: "Geen trainingen gevonden",
            noResultsDescription: "Probeer andere filters of controleer of je zoektermen kloppen.",
            filterMenu: "Filters",
            filterMenuClose: "Sluit",
            filterMenuShowResults: "Resultaten weergeven",
            sortingTitle: "Sorteeropties",
            price: "Budget",
            duration: "Duur training",
            sorting: {
              popularity: "Sorteren op populariteit",
              titleAsc: "Naam (A-Z)",
              titleDesc: "Naam (Z-A)",
              priceAsc: "Prijs laag naar hoog",
              priceDesc: "Prijs hoog naar laag"
            }
          },
          "en-EN": {
            themes: "Themes",
            type: "Type",
            allOptions: "All",
            resetFilters: "Reset filters",
            noResults: "No workouts found",
            noResultsDescription: "Try other filters or check if your search terms are correct.",
            filterMenu: "Filters",
            filterMenuClose: "Close",
            filterMenuShowResults: "View results",
            sortingTitle: "Sorting options",
            price: "Budget",
            duration: "Duration of training",
            sorting: {
              popularity: "Sort by popularity",
              titleAsc: "Name (A-Z)",
              titleDesc: "Name (Z-A)",
              priceAsc: "Price low to high",
              priceDesc: "Price high to low"
            }
          }
        },
        filterMenuIsVisible: false,
        popularTrainingsDropdownIsVisible: false
      },
      maxValue: null,
      minValue: null,
      searchClient,
      routing: {
        router: historyRouter(),
        stateMapping: singleIndexMapping(this.searchIndex)
      }
    };
  },
  mounted() {
    this.setEventListeners();
    this.setWindowViewport();
  },
  methods: {
    scrollUp() {
      window.scrollTo(0, 0);
    },
    toggleFilterMenuVisibility() {
      this.ui.filterMenuIsVisible = !this.ui.filterMenuIsVisible;
    },
    togglePopularTrainingsDropdown() {
      this.ui.popularTrainingsDropdownIsVisible = !this.ui.popularTrainingsDropdownIsVisible;
    },
    setWindowViewport() {
      const isNarrow = window.matchMedia(`(max-width: ${MEDIA_QUERY})`).matches;
      this.ui.filterMenuIsVisible = !isNarrow;
    },
    setEventListeners() {
      window.addEventListener("resize", this.setWindowViewport);
    },
    filterButtonClickHandler() {
      this.toggleFilterMenuVisibility();
    },
    popularTrainingsButtonClickHandler() {
      this.togglePopularTrainingsDropdown();
    },
  }
};
</script>
