<template>
  <div>
    <aside
      ref="inlineApplyBlock"
      class="training-details-apply container"
      :class="{ 'training-details-apply--has-sub-nav': hasSubNav }"
    >
      <div class="training-details-apply__layout">
        <div
          ref="js-scroll-to-form"
          class="training-details-apply__inner"
        >
          <slot name="training-details" />
          <div class="training-details-apply__info">
            <slot name="training-rating" />

            <usps-list
              v-if="usps"
              :usps="usps"
            />

            <price-indicator
              :base-price="basePrice"
              :discount-price="discountPrice"
              :price-with-eurosign="priceWithEurosign"
              :show-btw="showBtw"
            />
          </div>

          <div
            v-if="formattedTrainingRuns.length > 0"
            class="training-details-apply__form"
          >
            <label class="training-details-apply__select-label">
              Startdatum en locatie

              <div class="training-details-apply__select-container">
                <select
                  v-model="selectedCourseDayIndex"
                  name="startDate"
                  class="training-details-apply__select"
                >
                  <option value="0">Kies een datum</option>
                  <option
                    v-for="(trainingRun, index) in formattedTrainingRuns"
                    :key="trainingRun.id"
                    :value="index + 1"
                  >
                    {{ trainingRun.dropdowninput.inputLabel }}
                  </option>
                </select>

                <span class="fas fa-chevron-down" />
              </div>
            </label>

            <button
              v-if="selectedCourseDayIndex === 0 || selectedCourseDayIndex === '0'"
              :disabled="true"
              class="button--primary button--apply button--disabled"
            >
              Inschrijven
            </button>
            <a
              v-else
              :href="`./${slug}/inschrijven?option=${selectedCourseDayIndex - 1}`"
              class="button--primary button--apply"
            >Inschrijven</a>
          </div>

          <div class="training-details-apply__break" />

          <div class="training-details-apply__request-info-container">
            <button
              class="button--secondary button--quotation-toggle"
              @click.prevent="onQuotationButtonClick"
            >
              Offerte aanvragen
            </button>

            <div
              v-show="quotationInputIsVisible"
              class="training-details-apply__request-info-form"
            >
              <slot name="request-info-form" />
            </div>
          </div>

          <div
            class="training-details-apply__break training-details-apply__break--mobile-only"
          />
        </div>
      </div>
    </aside>

    <div
      v-show="stickyApplyBlockIsVisible"
      class="sticky-training-details-apply"
    >
      <form
        v-if="formattedTrainingRuns.length > 0"
        class="sticky-training-details-apply__form"
        @submit.prevent=""
      >
        <price-indicator
          v-if="discount || discountPrice"
          :base-price="basePrice"
          :price-with-eurosign="priceWithEurosign"
          :show-btw="showBtw"
          :discount-price="discountPrice"
          :show-discount="false"
        />

        <price-indicator
          v-else
          :base-price="basePrice"
        />

        <button
          v-if="selectedCourseDayIndex === 0 || selectedCourseDayIndex === '0'"
          class="button--primary button--apply"
          type="button"
          @click.prevent="scrollToForm()"
        >
          Inschrijven
        </button>
        <a
          v-else
          :href="`./${slug}/inschrijven?option=${selectedCourseDayIndex - 1}`"
          class="button--primary button--apply"
        >Inschrijven</a>
      </form>
    </div>

    <slot name="content-blocks" />

    <div
      id="course-days"
      class="training-details-dates container"
    >
      <div
        v-if="courseDays"
        class="training-details-dates__inner"
      >
        <h3 class="training-details-dates__title title">
          Data
        </h3>

        <div class="training-details-dates__select-container">
          <select
            v-model="selectedCourseDayIndex"
            name="startDate"
            class="training-details-dates__select"
          >
            <option
              v-for="(trainingRun,index) in formattedTrainingRuns"
              :key="trainingRun.id"
              :value="index"
            >
              {{ trainingRun.dropdowninput.inputLabel }}
            </option>
          </select>
          <span class="fas fa-chevron-down" />
        </div>
        
        <table
          v-for="(courseDay, index) in courseDays"
          :key="index"
          class="training-details-dates__table"
        >
          <tr>
            <th>Dag {{ (index + 1) }}</th>
            <th v-if="courseDay.date">
              Datum
            </th>
            <th v-if="courseDay.startTime">
              Starttijd
            </th>
            <th v-if="courseDay.endTime">
              Eindtijd
            </th>
          </tr>
          <tr>
            <td />
            <td v-if="courseDay.date">
              {{ courseDay.date }}
            </td>
            <td v-if="courseDay.startTime">
              {{ courseDay.startTime }}
            </td>
            <td v-if="courseDay.endTime">
              {{ courseDay.endTime }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingDetailsContent",
  props: {
    slug: {
      type: String,
      required: true
    },
    basePrice: {
      type: Number,
      required: true
    },
    discount: {
      type: Number,
      required: false,
      default: 0
    },
    discountPrice: {
      type: Number,
      required: false,
      default: null
    },
    formattedTrainingRuns: {
      type: Array,
      required: true
    },
    usps: {
      type: Array,
      required: false,
      default: () => []
    },
    hasSubNav: {
      type: Boolean,
      default: false
    },
    showBtw: {
      type: Boolean,
      default: true
    },
    priceWithEurosign: {
      type: Boolean,
      default: true
    },
    locale: {
      type: String,
      default: "nl-NL"
    },
  },
  data() {
    return {
      selectedCourseDayIndex: 0,
      quotationInputIsVisible: false,
      stickyApplyBlockIsVisible: false
    };
  },
  computed: {
    courseDays() {
      return this.formattedTrainingRuns?.[this.selectedCourseDayIndex - 1]?.courseDays
        ? this.formattedTrainingRuns[this.selectedCourseDayIndex - 1].courseDays
        : null;
    }
  },
  watch: {
    selectedCourseDayIndex() {
      this.setStartDateFieldValue();
    }
  },
  mounted() {
    this.toggleStickyApply();
    this.setStartDateFieldValue();

    if (
      window.location.search.includes("success") ||
      window.location.search.includes("token") ||
      window.location.search.includes("offerte")
    ) {
      this.quotationInputIsVisible = true;
      this.$refs["js-scroll-to-form"].scrollIntoView({ behavior: "smooth" });
    }
  },
  methods: {
    setStartDateFieldValue() {
      const select = document.querySelector(".training-details-apply__select");
      const startDate = document.querySelector("#form-input-trainingStartDate");

      if (select && startDate) {
        startDate.value = select.options[select.selectedIndex].text;
      }
    },
    toggleStickyApply() {
      if (!this.$refs.inlineApplyBlock) return null;

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.stickyApplyBlockIsVisible = false;
          } else {
            this.stickyApplyBlockIsVisible = true;
          }
        });
      });
      observer.observe(this.$refs.inlineApplyBlock);
    },
    toggleQuotationInput() {
      this.quotationInputIsVisible = !this.quotationInputIsVisible;
      if (this.quotationInputIsVisible && history.pushState) {
        this.insertUrlParam("offerte", "open");
        this.scrollToBottomOfferte(
          window.innerWidth >= 1024
            ? "#course-days"
            : ".training-details-content-blocks__section-inner",
          window.innerWidth >= 1024 ? screen.height * 0.4 : screen.height * 0.25
        );
      }
    },
    scrollToBottomOfferte(queryElementString, offset) {
      const element = document.querySelector(queryElementString);

      if (element) {
        const targetPosition = element.getBoundingClientRect().top;
        const offsetPosition = targetPosition + window.pageYOffset - offset;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    },
    insertUrlParam(key, value) {
      if (history.pushState) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set(key, value);
        const urlString = newUrl.toString();
        window.history.pushState({ path: urlString }, "", urlString);
      }
    },
    onQuotationButtonClick() {
      this.toggleQuotationInput();
    },
    scrollToForm() {
      const form = document.querySelector(".training-details-apply");
      form.scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    }
  }
};
</script>
