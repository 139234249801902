<template>
  <div
    v-if="selectedTraining"
    class="training-apply-form__summary"
  >
    <h3>Samenvatting van je inschrijving</h3>
    <ul class="training-apply-form__summary-list">
      <li class="training-apply-form__summary-list-item">
        <h4 class="training-apply-form__summary-list-title">
          Training/Cursus
        </h4>
        <span>{{ title }}</span>
      </li>
      <li
        v-if="selectedTrainingRunDate"
        class="training-apply-form__summary-list-item"
      >
        <h4 class="training-apply-form__summary-list-title">
          Datum
        </h4>

        <table
          v-for="(courseDay, index) in selectedTrainingRunDate"
          :key="index"
          class="training-details-dates__table"
        >
          <tr>
            <th>Dag {{ (index + 1) }}</th>
            <th v-if="courseDay.date">
              Datum
            </th>
            <th v-if="courseDay.startTime">
              Starttijd
            </th>
            <th v-if="courseDay.endTime">
              Eindtijd
            </th>
          </tr>
          <tr>
            <td />
            <td v-if="courseDay.date">
              {{ courseDay.date }}
            </td>
            <td v-if="courseDay.startTime">
              {{ courseDay.startTime }}
            </td>
            <td v-if="courseDay.endTime">
              {{ courseDay.endTime }}
            </td>
          </tr>
        </table>
      </li>
      <li
        v-if="selectedTrainingRunLocation"
        class="training-apply-form__summary-list-item"
      >
        <h4 class="training-apply-form__summary-list-title">
          Locatie
        </h4>
        <span>{{ selectedTrainingRunLocation }}</span>
      </li>
      <li
        v-if="showPrice"
        class="training-apply-form__summary-list-item"
      >
        <h4 class="training-apply-form__summary-list-title">
          Prijs
        </h4>
        <slot name="price-indicator" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TrainingApplyFormSummary",
  props: {
    title: {
      type: String,
      required: true
    },
    trainingRuns: {
      type: Array,
      required: true
    },
    price: {
      type: String,
      default: null
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    selectorHandle: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      selector: null,
      selectedTraining: null,
      field_training_summary: null,
      fields: {
        registration_code: null,
        registration_description: null,
        training_name: null,
        training_summary: null,
        training_price: null,
        date_and_location: null,
      }
    }
  },
  computed: {
    selectedTrainingRunDate(){
      return this.selectedTraining.courseDays
    },
    selectedTrainingRunLocation(){
      return this.selectedTraining.location
    }
  },
  mounted(){
    this.fields = {
      registration_code: document.querySelector(`input[name='registration_code']`) || null,
      registration_description: document.querySelector(`input[name='registration_description']`) || null,
      training_name: document.querySelector(`input[name='training_name']`) || null,
      training_summary: document.querySelector(`input[name='training_summary']`) || null,
      training_price: document.querySelector(`input[name='training_price']`) || null,
      date_and_location: document.querySelector(`select[name='${this.selectorHandle}']`) || null,
    }

    if(this.fields.date_and_location) { 
      this.fields.date_and_location.addEventListener("change", this.checkValue);
      this.checkValue();
    }
  },
  methods:{
    setFieldData(handle, data){
      if(!this.fields[handle]){ return }
      this.fields[handle].value = data
    },
    checkValue(){
      const id = (this.fields.date_and_location.value || "").split(" ").pop();
      this.selectedTraining = id ? this.trainingRuns.find(x => x.id === id): null;

      this.setFieldData("registration_code", id);
      this.setFieldData("registration_description", this.title);
      this.setFieldData("training_name", this.title);
      this.setFieldData("training_summary", this.selectedTraining.dropdowninput.inputValue );
      this.setFieldData("training_price", this.price);
    },
  },
};
</script>
