<template>
  <div
    class="relative flex flex-row items-center"
    :class="textClasses"
  >
    <label class="mr-2 font-bold text-inherit">{{ value }}</label>

    <!-- - 0.05 to solve rendering issues -->
    <div
      class="rating__stars"
      :style="`--rating: ${value / 2 - 0.05}`"
    />

    <slot />
  </div>
</template>

<script>
export default {
  name: "RatingIndicator",
  props: {
    value: {
      type: Number,
      required: true
    },
    textClasses: {
      type: String,
      default: "text-2xl text-[#dfa30e]"
    }
  }
};
</script>
