<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M5.52843 12.4715C5.26808 12.2111 5.26808 11.789 5.52843 11.5287L9.05703 8.00008L5.52843 4.47149C5.26808 4.21114 5.26808 3.78903 5.52843 3.52868C5.78878 3.26833 6.21089 3.26833 6.47124 3.52868L10.4712 7.52868C10.7316 7.78903 10.7316 8.21114 10.4712 8.47149L6.47124 12.4715C6.21089 12.7318 5.78878 12.7318 5.52843 12.4715Z" 
    />
  </svg>
</template>