<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
    <div class="flex flex-col gap-6 lg:gap-8 py-10">
      <div class="prose prose-a:text-white prose-a:font-normal md:prose-md lg:prose-lg text-[--neutrals-white]">
        <h2>
          <slot name="title" />
        </h2>

        <template
          v-if="$slots.description"
        >
          <slot name="description" />
        </template>
      </div>

      <div
        v-if="$slots['cta-list']"
        class="lg:col-span-10 flex flex-col md:flex-row flex-wrap gap-4 lg:gap-6"
      >
        <slot name="cta-list" />
      </div>
    </div>

    <div class="hidden md:block">
      <slot name="image" />
    </div>
  </div>
</template>
