<template>
  <div class="dropdown">
    <div class="dropdown__inner">
      <div 
        v-for="i in dropdownCount"
        :key="`dropdown__${i}`"
        class="dropdown__element"
      >
        <button 
          class="dropdown__control"
          :class="{'dropdown__control--active' : isOpen === i }"  
          @click="toggleDropdown(i)"
        >
          <slot :name="`dropdown-toggle--${i}`" />
          <svg 
            class="dropdown__icon"
            xmlns="http://www.w3.org/2000/svg" 
            width="14" 
            height="9" 
            viewBox="0 0 14 9" 
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L7 6.08579L12.2929 0.792893C12.6834 0.402369 13.3166 0.402369 13.7071 0.792893C14.0976 1.18342 14.0976 1.81658 13.7071 2.20711L7.70711 8.20711C7.31658 8.59763 6.68342 8.59763 6.29289 8.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z"
              fill="#287CB8"
            />
          </svg>
        </button>
        <div
          class="dropdown__content"
          :class="{ 'dropdown__content--show' : isOpen === i}"
        >
          <slot
            :name="`dropdown-content--${i}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "./../event-bus";
export default {
  props:{
    defaultOpen: {
      type: Number,
      default: -1,
    },
    dropdownCount: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isOpen: this.defaultOpen 
    };
  },
  methods: {
    toggleDropdown(index) {
      this.isOpen = this.isOpen !== index ? index : -1
      EventBus.$emit("toggleDropdown", this.isOpen);
    }
  }
};
</script>

<style lang="scss">
.dropdown__content {
  display: none;
}

.dropdown__content--show {
  display: block;
}
</style>