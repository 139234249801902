<template>
  <button
    class="button header-video-button"
    :class="[{ open: paused }]"
    @click="toggleVideo()"
  >
    {{ btnText }}
    <div class="icon__container">
      <i
        v-if="paused !== false"
        class="fas fa-times"
      />
      <i
        v-if="paused === false"
        class="fas fa-play"
      />
      <i
        v-if="paused === false"
        class="fas fa-play"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "HeaderVideoButton",
  data() {
    return {
      paused: false,
      header: null,
      video: null
    };
  },
  computed: {
    btnText() {
      return this.paused === false ? `Ons verhaal` : `Sluit video`;
    }
  },
  mounted() {
    this.header = document.getElementById("homepage-header");
    this.video = document.getElementById("video-player");
    if (this.video) {
      this.init();
    }
  },
  methods: {
    init() {
      this.video.addEventListener("pause", () => {
        this.toggleVideo(false);
      });
      this.video.addEventListener("ended", () => {
        this.toggleVideo(false);
      });
    },
    toggleVideo(value = !this.paused) {
      this.paused = value;
      if (this.paused) {
        this.header.classList.add("video");
        this.video.play();
      } else {
        this.video.load();
        this.header.classList.remove("video");
      }
    }
  }
};
</script>
