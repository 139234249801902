<template>
  <div>
    <button @click="toggleClickEventHandler">
      <slot name="toggle-content" />
    </button>

    <div
      v-show="isVisible"
      class="modal"
    >
      <div
        ref="backdrop"
        class="modal__backdrop"
      />

      <div
        v-if="isVisible"
        class="modal__inner"
      >
        <div class="modal__content">
          <button
            class="modal__toggle"
            @click="toggleClickEventHandler"
          >
            <i class="far fa-times" />
          </button>
          <slot name="modal-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    addEventListener() {
      this.$refs.backdrop.addEventListener("click", this.toggleModal);
    },
    removeEventListener() {
      this.$refs.backdrop.removeEventListener("click", this.toggleModal);
    },
    toggleModal() {
      this.isVisible = !this.isVisible;
    },
    toggleClickEventHandler() {
      this.toggleModal();
      this.isVisible ? this.addEventListener() : this.removeEventListener();
    }
  }
};
</script>
