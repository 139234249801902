<template>
  <div class="vue-video-player">
    <span class="time">{{ stringyfySeconds(currentTime) }}</span>
    <div
      class="bar"
      :style="{
        width: 100 - ((totalTime - currentTime) / totalTime) * 100 + '%'
      }"
    />
    <div class="video">
      <div
        class="button"
        :class="isPlaying ? 'pause' : 'play'"
        v-text="isPlaying ? '' : 'speel video af'"
      />
      <div
        class="overlay"
        :style="placeholder ? `background-image:url('${placeholder}')` : ''"
        :class="isPlaying ? 'hidden' : null"
        @click="playResume()"
      />
      <video
        id="video-player"
        ref="video"
      >
        <source
          :src="url"
          :type="'video/' + type"
        >
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      totalTime: 1,
      timer: null
    };
  },
  mounted() {
    const _this = this;
    this.$refs.video.addEventListener("loadeddata", () => {
      if (_this.$refs.video.readyState >= 3) {
        _this.totalTime = _this.$refs.video.duration;
        this.startTimer();
      }
    });
  },
  methods: {
    stringyfySeconds(seconds) {
      const minutes = Math.floor(seconds / 60);
      let cutSeconds = Math.floor(seconds % 60);
      if (cutSeconds < 10) {
        cutSeconds = "0" + cutSeconds;
      }
      return minutes + ":" + cutSeconds;
    },
    playResume() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.$refs.video.play();
        this.startTimer();
      } else {
        this.$refs.video.pause();
        this.stopTimer();
      }
    },
    startTimer() {
      const _this = this;
      this.timer = setInterval(() => {
        _this.currentTime = _this.$refs.video.currentTime;
        if (_this.currentTime === _this.totalTime) {
          this.playResume();
          _this.$refs.video.currentTime = 0;
        }
      }, 100);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>
