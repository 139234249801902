<template>
  <div
    ref="carousel"
    class="glide mx-auto max-w-full cursor-grab"
    :class="`glide--${customView} glide--${id} ${glideClasses} ${ {'!overflow-visible' : allowOverflow }}`"
    :data-slider-id="sliderId"
  >
    <div
      class="glide__track"
      :class="{'!overflow-visible' : allowOverflow}"
      data-glide-el="track"
    >
      <ul
        class="glide__slides"
        :class="{'!overflow-visible' : allowOverflow}"
      >
        <li 
          v-for="(slide, loopIndex) in slideCount"
          :key="`slide--${loopIndex}`" 
          :date-slide="slide"
          :date-glide-index="loopIndex"
          class="glide__slide"
          :class="[background, {' !overflow-visible' : allowOverflow}]"
        >
          <div
            class="w-full h-full" 
            :class="{'!overflow-visible' : allowOverflow}"
          >
            <slot 
              :name="`slide-${id}--${slide}`"
              :date-index="loopIndex"
            />
          </div>
        </li>
      </ul>
    </div>

    <div 
      v-if="sliderSettings.showBullets"
      class="glide__bullets flex flex-row flex-wrap justify-center items-center gap-4"
      :class="marginBullets"
      data-glide-el="controls[nav]"
    >
      <button 
        v-for="(slide, loopIndex) in slideCount"
        :key="`slide--${loopIndex}`"
        :data-slide="slide"
        :data-glide-index="loopIndex" 
        class="w-4 h-4 rounded-full"
        :class="[ glide.index === loopIndex ? 'bg-[--blue-b-670]' : 'bg-[--blue-muted-bm-400]']"
        :data-glide-dir="`=${loopIndex}`"
      />
    </div>

    <div
      v-if="sliderSettings.showControls"
      class="glide__arrows"
      data-glide-el="controls"
    >
      <button
        class="glide__arrow glide__arrow--left"
        data-glide-dir="<"
      >
        prev
      </button>
      <button
        class="glide__arrow glide__arrow--right"
        data-glide-dir=">"
      >
        next
      </button>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import dataLayerEventService from "../../dataLayerEventService.js";
import ViewportHelpers from "../../helpers/ViewportHelpers.js";

export default {
  name: "TwCarousel",
  props: {
    id: {
      type: Number,
      required: true
    },
    slideCount: {
      type: Number,
      required: true
    },
    sliderSettings: {
      type: Object,
      default: () => ({
        showBullets: true,
        showControls: false
      }),
    },
    sliderProps: {
      type: Object,
      default: () => ({
        // See https://glidejs.com/docs/options/ for all availible options
      }),
    },
    customView: {
      type: String,
      default: "default"
    },
    background: {
      type: String,
      default: "bg-[--neutrals-n-50]"
    },
    glideClasses: {
      type: String,
      default: ""
    },
    marginBullets:{
      type: String,
      default: "mt-6"
    },
    sliderId: {
      type: String,
      required: true
    },
    allowOverflow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      glide: null,
      focusAt: 0,
      peek: { before: 0, after: 0},
      gap: 16,
      gridColumns: null,
      currentScreenWidth: null,
      loading: false
    };
  },
  beforeUnmount() {
    this.glide.destroy();
  },
  beforeMount () {
    const { type, startAt, perView, focusAt,gap, autoplay, hoverpause, keyboard, bound, swipeThreshold, dragThreshold, perTouch, touchRatio, touchAngle, animationDuration, rewind, rewindDuration, animationTimingFunc, direction, peek, breakpoints, classes, throttle } = this.sliderProps;

    const defaultClasses = {
      direction: {
        ltr: "glide--ltr",
        rtl: "glide--rtl"
      },
      slider: "glide--slider",
      carousel: "glide--carousel",
      swipeable: "glide--swipeable",
      dragging: "glide--dragging",
      cloneSlide: "glide__slide--clone",
      activeNav: "glide__bullet--active",
      activeSlide: "glide__slide--active",
      disabledArrow: "glide__arrow--disabled"
    };

    this.glide = new Glide(`.glide--${this.id}`, {
      type: type ?? "carousel",
      startAt: startAt ?? 0,
      perView: perView ?? 1,
      focusAt: focusAt ?? this.focusAt,
      gap: gap ?? this.gap,
      autoplay: autoplay ?? false,
      hoverpause: hoverpause ?? true,
      keyboard: keyboard ?? false,
      bound: bound ?? false,
      swipeThreshold: swipeThreshold ?? 80,
      dragThreshold: dragThreshold ?? 120,
      perTouch: perTouch ?? false,
      touchRatio: touchRatio ?? 0.5,
      touchAngle: touchAngle ?? 45,
      animationDuration: animationDuration ?? 400,
      rewind: rewind ?? false,
      rewindDuration: rewindDuration ?? 800,
      animationTimingFunc: animationTimingFunc ?? "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
      direction: direction ?? "ltr",
      peek: peek ?? this.peek,
      breakpoints: breakpoints ?? {},
      classes: classes ?? defaultClasses,
      throttle: throttle ?? 25,
    });
  },
  mounted() {
    this.glide.on(["resize"], () => {
      this.updateGlide();
    });
    this.glide.on(["mount.after", "run"], () => {
      this.pushDataLayerEvent(this.glide.index);
    });
    this.glide.mount();
    this.updateGlide();
    this.pushDataLayerEvent();
    
  },
  methods:{
    inViewPort() {
      return ViewportHelpers.checkIfElementIsInViewportWindow(this.$refs['carousel']);
    },
    pushDataLayerEvent(index){
      if(index >= 0 && this.inViewPort() && !this.loading){
        const data = {
          sliderId: this.sliderId,
          slideIndex: index
        };
        dataLayerEventService.pushSliderEvent(data);
      }
    },
    updateGlide() {
      this.calculateCustomCarouselView();
      this.setCtaClickWatchers();
    },
    setCtaClickWatchers() {
      const ctaList = document.querySelectorAll(`div[data-carousel-el^='carousel-${this.id}'] a`);

      ctaList.forEach(ctaItem => {
        const self = this;
        ctaItem.addEventListener("click", function() {
          self.handleCtaClick(ctaItem);
        });
      });
    },
    handleCtaClick(ctaItem) {
      dataLayerEventService.pushSliderCtaClick({
        eventName: "click_carousel",
        sliderId: this.sliderId,
        slideIndex: this.glide.index,
        ctaItem
      });
    },
    calculateCustomCarouselView() {
      if (this.customView === "carousel") {
        this.loading = true;
        const glideWidth = document.querySelector(`.glide--${this.id}`).offsetWidth;

        const maxSlideWidth = 1104;
        let availableSpace = 0;
        if (glideWidth > maxSlideWidth) {
          this.focusAt = "center";
          this.gap = 24;
          availableSpace = ((glideWidth - maxSlideWidth));
          const spaceAround = Math.floor(availableSpace / 2);
          this.peek = { before: spaceAround, after: spaceAround };
        } else {
          this.peek = { before: 0, after: glideWidth * 0.15 }
          this.focusAt = 0;
          this.gap = 16;
        }

        this.glide.update({
          peek: this.peek,
          focusAt: this.focusAt,
          gap: this.gap
        });
        this.loading = false;
      }
    }
  }
};
</script>
