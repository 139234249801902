<template>
  <svg
    class="fill-current"
    width="24"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M16.618 18.532a9 9 0 1 1 1.414-1.414l3.675 3.675a1 1 0 1 1-1.414 1.414l-3.675-3.675ZM4 11.5a7 7 0 1 1 12.042 4.856 1.029 1.029 0 0 0-.186.186A7 7 0 0 1 4 11.5Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
