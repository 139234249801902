<template>
  <div class="block__cases logo-cases">
    <div class="logos">
      <template v-for="(l, index) in data">
        <button
          v-if="showCase"
          :key="'logoBtn-' + index"
          class="logo is-btn"
          @click="setCase(index)"
        >
          <slot :name="'logo' + index" />
        </button>

        <a
          v-else-if="l.url"
          :key="'logoLink-' + index"
          class="logo is-link"
          :href="l.url"
        >
          <slot :name="'logo' + index" />
        </a>

        <div
          v-else
          :key="'logoLink-' + index"
          class="logo no-link"
        >
          <slot :name="'logo' + index" />
        </div>
      </template>
    </div>

    <div
      v-if="showCase"
      class="case"
    >
      <div class="case__image">
        <div
          v-for="index in data.length"
          :key="'caseImg-' + index"
          class="image-holder"
          :class="{ showing: index - 1 === selectedIndex }"
        >
          <slot :name="'img' + (index - 1)" />
        </div>
      </div>

      <div
        ref="case"
        class="case__content"
      >
        <div class="content__logo">
          <div
            v-for="index in data.length"
            :key="'caseLogo-' + index"
            class="content__logo__holder"
            :class="{ showing: index - 1 === selectedIndex }"
          >
            <slot :name="'logo' + (index - 1)" />
          </div>
        </div>

        <div class="content__qoute">
          "{{ data[selectedIndex].qoute }}"
        </div>

        <div class="content__sender">
          {{ data[selectedIndex].sender }}
        </div>

        <a
          class="case-link"
          :href="data[selectedIndex].url"
        >
          <span>{{ data[selectedIndex].url }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCase: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: 0
    };
  },
  methods: {
    setCase(i) {
      this.selectedIndex = i;

      const element = this.$refs.case;
      //get position from top;
      const bodyRect = document.body.getBoundingClientRect(),
        elemRect = element.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;

      //scrolls the case top to be the middle of the window;
      window.scrollTo(0, offset - window.innerHeight / 2);
    }
  }
};
</script>
