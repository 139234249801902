<template>
  <ul class="usps-list">
    <li
      v-for="(usp, index) in transformedUspList"
      :key="`usp--${index}`"
      class="usps-list__item"
    >
      <GroupsIcon
        v-if="usp.icon.value === 'uspContactHours'"
        :fill="iconFill"
        class="usps-list__icon"
      />
      <CalendarIcon
        v-else-if="usp.icon.value === 'uspDuration'"
        :fill="iconFill"
        class="usps-list__icon"
      />
      <ClockIcon
        v-else-if="usp.icon.value === 'uspDays'"
        :fill="iconFill"
        class="usps-list__icon"
      />
      <BookIcon
        v-else-if="usp.icon.value === 'uspSelfStudy'"
        :fill="iconFill"
        class="usps-list__icon"
      />
      <RestaurantIcon
        v-else-if="usp.icon.value === 'uspDayPackage'"
        :fill="iconFill"
        class="usps-list__icon"
      />
      <span>{{ usp.text }}</span>
    </li>
  </ul>
</template>

<script>
import BookIcon from "./icons/BookIcon.vue";
import CalendarIcon from "./icons/CalendarIcon.vue";
import ClockIcon from "./icons/ClockIcon.vue";
import GroupsIcon from "./icons/GroupsIcon.vue";
import RestaurantIcon from "./icons/RestaurantIcon.vue";

export default {
  name: "UspsList",
  components: { BookIcon, CalendarIcon, ClockIcon, GroupsIcon, RestaurantIcon },
  props: {
    usps: {
      type: Array,
      required: true
    },
    iconFill: {
      type: String,
      default: "#7E9492"
    }
  },
  computed: {
    transformedUspList() {
      return this.$props.usps.map(usp => {
        return {
          icon: usp.icon || usp.uspIcon,
          text: usp.text || usp.uspText
        };
      });
    }
  }
};
</script>
