<template>
  <a
    v-if="link.url"
    :href="link.url"
    :target="target"
    :data-styling-type="styling"
    :disabled="isDisabled ? 'disabled' : null"
    :aria-label="link.ariaLabel"
    :class="[
      'block group rounded-sm outline-none focus:outline-none',
      isFullWidthMobile ? 'w-full' : 'max-w-max',
      isFullWidthDesktop ? 'lg:w-full' : 'lg:w-auto lg:max-w-max',
      { 'min-w-[9.25rem]' : hasMinWidth },
      { 'py-[0.6875rem] lg:py-[0.4375rem] px-4 bg-[currentColor] rounded-[2px]': isButton },
      
      { 'text-[--blue-b-670] hover:text-[--blue-b-500] focus:text-[--blue-b-670]': isBaseStyling && !isDisabled },
      { 'text-[--blue-b-400] hover:text-[--blue-b-200] focus:text-[--blue-b-500]': isPrimaryStyling && !isDisabled },
      { 'text-[--neutrals-white] hover:text-[--blue-muted-bm-100] focus:text-[--neutrals-white]': isSecondaryStyling && !isDisabled },
      { 'text-[--neutrals-n-75] hover:text-[--neutrals-n-75] focus:text-[--neutrals-n-75] cursor-not-allowed': isDisabled },
      
      { 'border-[currentColor] hover:border-[currentColor] focus:border-[currentColor] border-solid border-[1px]': (isButton && !isSecondaryStyling && !hasTransparentBorder) || (isButton && hasTransparentBorder) },
      { 'border-[--blue-b-400] hover:border-[--blue-b-300] focus:border-[--blue-b-400]  border-solid border-[1px]': isButton && isSecondaryStyling && !isDisabled && !hasTransparentBorder }
    ]"
  >
    <div
      :class="[
        iconLocation,
        'flex gap-2 items-center text-base font-normal roboto w-full',
        isButton ? 'justify-center text-center' : 'justify-start text-left',
        { 'group-hover:underline group-focus:underline': !isButton },
        {
          'text-[--neutrals-white] group-hover:text-[--neutrals-white] group-focus:text-[--neutrals-white]':
            isBaseStyling && isButton && !isDisabled
        },
        {
          'text-[--blue-b-670] group-hover:text-[--blue-b-500] group-focus:text-[--blue-b-670]':
            isBaseStyling && !isButton && !isDisabled
        },
        {
          'text-[--neutrals-white] group-hover:text-[--neutrals-white] group-focus:text-[--neutrals-white]':
            isPrimaryStyling && isButton && !isDisabled
        },
        {
          'text-[--blue-b-400] group-hover:text-[--blue-b-200] group-focus:text-[--blue-b-500]':
            isPrimaryStyling && !isButton && !isDisabled
        },
        {
          'text-[--blue-b-400] group-hover:text-[--blue-b-300] group-focus:text-[--blue-b-400]':
            isSecondaryStyling && !isDisabled
        },
        {
          'text-[--neutrals-n-400] group-hover:text-[--neutrals-n-400] group-focus:text-[--neutrals-n-400]':
            isDisabled
        }
      ]"
    >
      <ArrowRightIcon 
        v-if="isSameDomain" 
        class="py-[2px]"
      />
      <PhoneIcon 
        v-else-if="link.typeHandle === 'phone'" 
        class="py-[2px]"
      />
      <EnvelopeIcon 
        v-else-if="link.typeHandle === 'email'" 
        class="py-[2px]"
      />
      <FilePdfIcon 
        v-else-if="isPdfAsset" 
        class="py-[2px]"
      />
      <DownloadIcon 
        v-else-if="link.typeHandle === 'asset'" 
        class="py-[2px]"
      />
      <i
        v-else-if="faIcon"
        class="py-[2px]"
        :class="faIcon"
      />

      <span>{{ link?.title ? link.title : link.text }}</span>
    </div>
  </a>
</template>

<script>
import ArrowRightIcon from "@/components/icons/ArrowRightIcon";
import PhoneIcon from "@/components/icons/PhoneIcon";
import EnvelopeIcon from "@/components/icons/EnvelopeIcon";
import FilePdfIcon from "@/components/icons/FilePdfIcon";
import DownloadIcon from "@/components/icons/DownloadIcon";

// Linkit field icon map
// see: https://github.com/presseddigital/linkit#configuring-linkit
const ICON_MAP = {
  // LINK TYPES BASIC
  "url": "fa-solid fa-arrow-right",

  // URL generic types
  "internal": "fa-solid fa-arrow-right",
  "external": "fa-solid fa-arrow-up-right-from-square",

  // LINK TYPES SOCIAL
  "twitter": "fa-brands fa-twitter",
  "facebook": "fa-brands fa-facebook",
  "instagram": "fa-brands fa-instagram",
  "linkedin": "fa-brands fa-linkedin",

  // LINK TYPES ELEMENTS
  "entry": "fa-solid fa-arrow-right",
  "category": "fa-solid fa-arrow-right",
  "user": "fa-solid fa-arrow-right",
  "product": "fa-solid fa-arrow-right",

};

// For Action Button styling without background: use !isButton && styling = secondary
// Secondary styling combined with !isButton, will remove hover:underline
export default {
  name: "LinkElement",
  components: {
    DownloadIcon,
    FilePdfIcon,
    EnvelopeIcon,
    PhoneIcon,
    ArrowRightIcon
  },
  props: {
    link: {
      type: Object,
      required: true
    },
    isButton: {
      type: Boolean,
      default: true
    },
    isFullWidthMobile: {
      type: Boolean,
      default: false
    },
    isFullWidthDesktop: {
      type: Boolean,
      default: false
    },
    styling: {
      type: String,
      default: "base",
      validator: (value) => ["base", "primary", "secondary"].includes(value)
    },
    hasMinWidth:{
      type: Boolean,
      default: false
    },
    hasTransparentBorder:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isSameDomain: false
    };
  },
  computed: {
    isDisabled() {
      return this.link.disabled || false;
    },
    isBaseStyling() {
      return this.styling === "base";
    },
    isPrimaryStyling() {
      return this.styling === "primary";
    },
    isSecondaryStyling() {
      return this.styling === "secondary";
    },
    target() {
      return this.link.targetString || this.link.target || "_self";
    },
    iconLocation() {
      return this.isSameDomain ? "flex-row-reverse" : "flex-row";
    },
    isPdfAsset() {
      return this.link.typeHandle === "asset" && this.link.url.endsWith('.pdf');
    },
    faIcon() {
      let lookup = this.link.typeHandle;

      if (this.link.typeHandle === "url") {
        lookup = this.isSameDomain ? "internal" : "external";
      }

      return ICON_MAP[lookup];
    }
  },
  mounted() {
    this.checkDomain();
  },
  methods: {
    checkDomain() {
      if (this.link.url) {
        const isPathLink = this.link.url.startsWith("/");
        const isAnchorLink = this.link.url.startsWith("#");
        const isLocalLink = this.link.url.includes(window.location.origin);
        this.isSameDomain = isPathLink || isAnchorLink || isLocalLink;
      }
    }
  }
};
</script>
