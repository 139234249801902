<template>
  <div>
    <div
      v-if="basePrice !== null"
      class="price-indicator"
    >
      <!-- Price -->
      <div class="price-indicator__price">
        <span
          v-if="showDiscount && discountPrice !== null && discountPrice < basePrice"
          class="price-indicator__discount"
        >
          <span v-if="priceWithEurosign">€<RenderNumber :text="discountPrice" /></span>
          <span v-else>
            <RenderNumber 
              :text="discountPrice" 
              :is-money="false"
            />
            <sub class="price-indicator__subtext"> EUR</sub>
          </span>
        </span>
        <span class="price-indicator__base-price">
          <span v-if="priceWithEurosign">€ <RenderNumber :text="basePrice" /></span>
          <span v-else>
            <RenderNumber 
              :text="basePrice" 
              :is-money="false" 
            />EUR
          </span>
        </span>
      </div>
    </div>

    <!-- BTW -->
    <div
      v-if="showBtw"
      class="price-indicator__btw"
    >
      Inclusief BTW
    </div>
    <div
      v-else
      class="price-indicator__btw"
    >
      Vrij van BTW
    </div>
  </div>
</template>

<script>
import RenderNumber from './ui/RenderNumber.vue';
export default {
  name: "PriceIndicator",
  components: { RenderNumber },
  props: {
    basePrice: {
      type: Number,
      default: 0
    },
    discountPrice: {
      type: Number,
      default: null
    },
    showDiscount: {
      type: Boolean,
      default: true
    },
    showBtw: {
      type: Boolean,
      default: true
    },
    priceWithEurosign: {
      type: Boolean,
      default: true
    }
  }
};
</script>
