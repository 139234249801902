<template>
  <div
    v-if="showPopup && info"
    class="banner_outer banner_notification"
  >
    <div class="banner_inner container">
      <div class="banner_content">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="info.content" />
        
        <button
          class="close_btn"
          @click="setLocalStorageVariable(true)"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerNotification",
  props: {
    info: { type: Object, required: true }
  },
  data() {
    return {
      showPopup: false
    };
  },
  mounted() {
    this.getPopupState();
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    getPopupState() {
      if (this.checkIfNewBannerId()) {
        this.toggle(true);
      }
    },
    checkIfNewBannerId() {
      return !window.localStorage.getItem(this.info.id);
    },
    setLocalStorageVariable(value) {
      window.localStorage.setItem(this.info.id, value);
      this.toggle(!value);
    },
    toggle(value) {
      this.showPopup = value;
      setTimeout(
        function () {
          this.moveNavMenus();
        }.bind(this),
        500
      );
    },
    moveNavMenus() {
      const banner = document.querySelectorAll(".banner_notification");
      const navMains = document.querySelectorAll(".nav-main");
      const bannerHeight = banner && banner.length ? banner[0].offsetHeight : 0;
      const stylingOffset = this.showPopup ? `${bannerHeight}px` : "0px";
      navMains.forEach(element => (element.style.top = stylingOffset));
    },
    myEventHandler() {
      this.moveNavMenus();
    }
  }
};
</script>
