<template>
  <svg
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M13.707 4.30531C13.4559 4.04536 13.0841 3.9411 12.7345 4.03262C12.3849 4.12413 12.1118 4.39718 12.0203 4.74681C11.9288 5.09644 12.033 5.46824 12.293 5.71931L17.657 11.0833H4C3.44772 11.0833 3 11.531 3 12.0833C3 12.6356 3.44772 13.0833 4 13.0833H17.657L12.293 18.4473C11.914 18.8397 11.9194 19.4634 12.3052 19.8491C12.6909 20.2349 13.3146 20.2403 13.707 19.8613L20.778 12.7903C21.1684 12.3998 21.1684 11.7668 20.778 11.3763L13.707 4.30531Z"
      fill-rule="evenodd"
    />
  </svg>
</template>
