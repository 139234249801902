<template>
  <div v-if="explanationCopy">
    <span
      v-if="explanationCopy.tag"
      class="quiz__description-tag"
    >{{ explanationCopy.tag }}</span>

    <h1
      v-if="explanationCopy.title"
      class="h1 h1--large"
    >
      {{ explanationCopy.title }}
    </h1>

    <div class="quiz__dots" />

    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="explanationCopy.description"
      class="quiz__description"
      v-html="explanationCopy.description"
    />
    <!--eslint-enable-->

    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="explanationCopy.info"
      class="quiz__description"
      v-html="explanationCopy.info"
    />
    <!--eslint-enable-->

    <slot name="button" />
  </div>
</template>

<script>
export default {
  name: "TheQuizExplanation",
  props: {
    explanationCopy: {
      required: true,
      type: Object
    }
  }
};
</script>
