<template>
  <div
    class="question"
    :class="{ 'question--open': hasClassName }"
    @click="openClose"
  >
    <button class="question__button">
      <span>{{ question }}</span>
      <div
        class="icon"
        :class="{ rotate: open }"
      >
        <span class="fa-solid fa-chevron-down" />
      </div>
    </button>
    <!-- "question__answer" is a mask for the wysiwyg so javascript can get the height of the wysiwyg even when the mask is 0px; -->
    <div
      ref="mask"
      class="question__answer mt-4"
    >
      <!-- Use v-html for wysiwyg reasons.-->
      <!-- eslint-disable-next-line -->
      <div ref="content" class="prose lg:prose-lg" v-html="answer"></div>
    </div>
  </div>
</template>

<script>
// Animation Timer in MS
const totalTime = 200;

export default {
  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      open: false,
      hasClassName: false,
      animating: false
    };
  },
  methods: {
    openClose() {
      // doesn't do anything if it's already animating;
      if (this.animating) {
        return;
      }
      this.animating = true;

      // toggle open
      this.open = !this.open;

      // create var for inside functions (timeouts)
      const _this = this;
      if (this.open) {
        // --open animation--
        // 3 steps, set animation, start animation, end animation (after set Time);
        this.$refs.mask.style.transition = "height " + totalTime + "ms";
        setTimeout(() => {
          _this.$refs.mask.style.height = this.$refs.content.offsetHeight + "px";
          setTimeout(() => {
            _this.hasClassName = true;
            _this.animating = false;
            _this.$refs.mask.removeAttribute("style");
          }, totalTime);
        }, 0);
      } else {
        // --close animation--
        // 3 steps, set animation, start animation, end animation; (after set Time);
        this.$refs.mask.style.transition = "height " + totalTime + "ms";
        this.$refs.mask.style.height = this.$refs.content.offsetHeight + "px";
        setTimeout(() => {
          _this.$refs.mask.style.height = "1px";
          setTimeout(() => {
            _this.hasClassName = false;
            _this.animating = false;
            _this.$refs.mask.removeAttribute("style");
          }, totalTime);
        }, 0);
      }
    }
  }
};
</script>
