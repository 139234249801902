<template>
  <a
    :href="item.url"
    :class="{ 'trainings-overview-search__card--is-Dark': isDark }"
    class="trainings-overview-search__card"
  >
    <div class="trainings-overview-search__card-header">
      <h2 class="trainings-overview-search__card-title">{{ item.title }}</h2>

      <div
        v-if="item.discountLabel"
        class="trainings-overview-search__card-discount-label"
      >
        {{ item.discountLabel }}
      </div>
    </div>

    <div class="trainings-overview-search__card-body">
    
      <div class="trainings-overview-search__card-body-left-column">
        
        <div class="trainings-overview-search__card-description">
          <p><strong>{{ item.subTitle }}</strong></p>
        </div>

        <rating-indicator
          v-if="item.rating"
          :value="item.rating"
        >
          <span v-if="item.reviewsAmount > 0">({{ item.reviewsAmount }})</span>
        </rating-indicator>
      </div>
      <div class="trainings-overview-search__card-body-right-column">
        <price-indicator
          v-if="item.discount"
          :base-price="item.price"
          :discount-price="item.discountPrice"
          :price-with-eurosign="item.priceWithEurosign"
          :show-btw="item.showBtw"
        />

        <price-indicator
          v-else
          :base-price="item.price"
          :price-with-eurosign="item.priceWithEurosign"
          :show-btw="item.showBtw"
        />
      </div>
    </div>

    <div
      v-if="item.usps.length > 0"
      class="trainings-overview-search__card-usps"
    >
      <usps-list
        :usps="item.usps"
        :icon-fill="iconFill"
      />
    </div>

    <div class="trainings-overview-search__card-footer">
      <div v-if="item.type || item.themes">
        <ul
          v-if="item.type.length > 0 || item.themes.length > 0"
          class="trainings-overview-search__card-attribute-list"
        >
          <li
            v-for="itemType in item.type"
            :key="itemType.id"
            class="trainings-overview-search__card-attribute-list-item trainings-overview-search__card-attribute-list-item--filled"
          >
            <span>{{ itemType.title }}</span>
          </li>
          <li
            v-for="itemThemes in item.themes"
            :key="itemThemes.id"
            class="trainings-overview-search__card-attribute-list-item"
          >
            <span>{{ itemThemes.title }}</span>
          </li>
        </ul>
      </div>

      <div class="trainings-overview-search__card-footer-bottom">
        <div>
          <price-indicator
            v-if="item.discountPrice"
            :base-price="item.price"
            :price-with-eurosign="item.priceWithEurosign"
            :show-btw="item.showBtw"
          />

          <price-indicator
            v-else
            :base-price="item.price"
            :price-with-eurosign="item.priceWithEurosign"
            :show-btw="item.showBtw"
          />
        </div>

        <a
          :href="item.url"
          class="trainings-overview-search__card-link button--primary button--primary--single-color"
        >{{ ui.labels.moreInfo }}</a>
      </div>
    </div>
  </a>
</template>

<script>
import UspsList from "@/components/UspsList";
import PriceIndicator from "@/components/PriceIndicator";
import RatingIndicator from "@/components/RatingIndicator";

export default {
  name: "TrainingCard",
  components: {
    UspsList, PriceIndicator, RatingIndicator
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    isDark: {
      type: Boolean,
      default: false
    },
    iconFill: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ui: {
        labels: {
          moreInfo: "Meer informatie"
        }
      }
    };
  }
};
</script>
