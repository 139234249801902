<template>
  <div class="radio-field-with-instructions">
    <slot />
    <span class="radio-field-with-instructions__style" />
    <button
      class="open-instructions"
      @click="onOpen"
    >
      <i class="fal fa-info-circle" />
    </button>
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "RadioFieldWithInstructions",
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    }
  },
  methods: {
    onOpen(event) {
      event.preventDefault();
      EventBus.$emit("openInstructionsPopup", {
        image: this.image,
        title: this.title,
        content: this.content
      });
    }
  }
};
</script>
