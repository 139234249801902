<template>
  <div
    v-if="hideOnSearchPage"
    class="nav-search input"
    :class="classes"
  >
    <div class="nav-search__inner">
      <i class="input__icon far fa-search" />
      <input
        v-model="searchTerm"
        class="input__field"
        type="search"
        :placeholder="placeholder"
        @keyup.enter="search"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    classes: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: false,
      searchTerm: null
    };
  },
  computed: {
    hideOnSearchPage() {
      return window.location.pathname !== "/search";
    }
  },
  methods: {
    search($event) {
      window.location.href = "/search?q=" + $event.target.value;
    }
  }
};
</script>
