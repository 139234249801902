<template>
  <button
    class="nav-button--switch"
    @click="toggleMenu()"
  >
    <span>{{ btnText }}</span>
    <i
      v-if="!open"
      class="fas fa-bars"
    />
    <i
      v-else
      class="fas fa-times"
    />
  </button>
</template>

<script>
import EventBus from "./../event-bus";
export default {
  name: "NavigationButton",
  data() {
    return {
      open: false
    };
  },
  computed: {
    btnText() {
      if (this.open) {
        return this.lang === "en" ? "Close" : "Sluit";
      }
      return "Menu";
    }
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
    });
  },
  methods: {
    toggleMenu() {
      EventBus.$emit("toggleMenu");
      if (this.open) {
        document.body.classList.add("menu-open");
      } else {
        document.body.classList.remove("menu-open");
      }
    }
  }
};
</script>
