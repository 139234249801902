<template>
  <div 
    v-if="pages.length > 1"
    data-type="TwBreadcrumbs" 
    class="block max-w-full mx-0 my-0 px-4 py-4 lg:px-0 overflow-hidden z-[2]"
  >
    <div class="flex flex-col md:grid md:grid-cols-block-grid gap-y-4 lg:gap-y-6 gap-x-4 lg:gap-x-6">
      <div class="col-start-2 col-span-12 flex flex-col w-full">
        <nav 
          class="flex pt-1" 
          aria-label="Breadcrumb"
        >
          <ol 
            role="list" 
            class="flex items-center flex-nowrap gap-2"
          >
            <li 
              v-for="page in pages" 
              :key="page.name"
            >
              <div
                class="flex flex-nowrap items-center gap-0 lg:gap-2"
                :class="page.position === pages.length - 1 ? 'flex' : 'hidden lg:flex'"
              >
                <!-- Arrow -->
                <ChevronRightIcon
                  :class="[
                    'text-[--blue-b-400] m-0 text-xs transition-transform rotate-180 lg:rotate-0',
                    { 'lg:hidden': page.position === 1 }
                  ]"  
                />

                <!-- Path links -->
                <a 
                  v-if="!page.current"
                  :href="page.url" 
                  class="m-0 text-xs font-normal font-[roboto] text-[--blue-b-400] hover:text-[--neutrals-n-500] focus:text-[--neutrals-n-500] block"
                >
                  {{ page.title }}
                </a>

                <!-- Current page -->
                <p 
                  v-else
                  class="m-0 text-xs font-normal font-[roboto] text-[--neutrals-n-500] hidden lg:block"
                  aria-current="page"
                >
                  {{ page.title }}
                </p>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";

export default {
  name: "TwBreadcrumbs",
  components: { ChevronRightIcon },
  props: {
    pages: {
      type: Array,
      required: true
    }
  }
};
</script>
