<template>
  <div
    ref="element"
    class="table__leading-column"
    :style="{ transform: 'translateX(' + left + 'px)' }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "TableTitle",
  data() {
    return {
      left: 0,
      scrollContainer: null
    };
  },
  mounted() {
    this.setScroll();
  },
  methods: {
    setScroll() {
      const element = this.$refs.element;
      this.scrollContainer = element.closest(".table");
      this.scrollContainer.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      this.left = this.scrollContainer.scrollLeft;
    }
  }
};
</script>
