<template>
  <div class="flex flex-col gap-6 lg:gap-10">
    <div class="prose prose-a:font-normal lg:prose-lg">
      <h2 v-if="$slots['title']">
        <slot name="title" />
      </h2>

      <slot />
    </div>

    <div
      v-if="$slots['cta-list']"
      class="lg:col-span-10 flex flex-col lg:flex-row flex-wrap gap-4 lg:gap-6"
    >
      <slot name="cta-list" />
    </div>
  </div>
</template>
