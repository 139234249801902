const dataLayerEventService = {
  pushSliderCtaClick({ eventName, sliderId, slideIndex, ctaItem }) {
    this.push({
      event: eventName || sliderId.split('--')[0] + '_clicked',
      slider_id: sliderId,
      slider_position: slideIndex,
      click_text: ctaItem.innerText,
      click_url: ctaItem.href
    });

    return this;
  },

  pushSliderEvent({ eventName, sliderId, slideIndex }) {
    this.push({
      event: eventName || sliderId.split('--')[0] + '_viewed',
      slider_id: sliderId,
      slider_position: slideIndex
    });

    return this;
  },

  push(gtmEvent) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gtmEvent);
  }
}

export default dataLayerEventService;
