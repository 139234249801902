<template>
  <div
    class="nav-column"
    :class="[classes, open ? 'nav-column--open' : null]"
  >
    <h4
      class="nav-column__title"
      @click="switchState()"
    >
      {{ column.title }}
    </h4>
    <div class="dots" />
    <div class="nav-column__content">
      <a
        v-for="(item, i) in column.items"
        :key="i"
        :href="item.url"
      >{{ item.title }}</a>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "NavigationFullscreenColumn",
  props: {
    classes: {
      type: String,
      required: false,
      default: ""
    },
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    switchState() {
      this.open ^= true;
    }
  }
};
</script>
