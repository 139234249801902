<template>
  <div
    v-if="open"
    class="video-modal"
  >
    <div
      class="video-modal__background"
      @click="onClose"
    >
      <div class="loader">
        <div
          v-for="n in 12"
          :key="n"
        />
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="video-modal__content"
      v-html="videoRaw"
    />
    <!--eslint-enable-->
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "VideoModal",
  data() {
    return {
      open: false,
      video: null,
      videoRaw: null
    };
  },
  mounted() {
    EventBus.$on("openVideoModal", data => {
      this.open = true;
      this.video = data.video;
      this.videoRaw = this.getVideoRaw();
      document.body.classList.add("video-modal--open");
    });
  },
  methods: {
    onClose() {
      this.open = false;
      document.body.classList.remove("video-modal--open");
    },
    getVideoRaw() {
      if (this.video.includes("vimeo")) {
        return `<figure><iframe src="${this.video}?autoplay=1&autopause=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></figure>`;
      } else if (this.video.includes("youtube")) {
        return `<figure><iframe src="${this.video}?&autoplay=1&autopause=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></figure>`;
      }
    }
  }
};
</script>
