<template>
  <div class="">
    <form
      class="bg-[--neutrals-white] text-base text-[--neutrals-n-500] flex h-10 overflow-hidden rounded-sm"
      @submit.prevent="search()"
    >
      <label
        for="search_form"
        class="sr-only"
      >{{ searchLink.text }}</label>
      <input
        id="search_form"
        ref="search_form"
        v-model="query"
        name="search_form"
        type="text"
        class="border-1 border-solid border-[--neutrals-white] bg-[--neutrals-white] flex-1 flex items-center justify-center border-0 ring-0 focus:ring-0 placeholder-[--neutrals-n-100]"
        :placeholder="`${searchLink.text}...`"
      >
      <button
        type="submit"
        class="w-14 flex items-center justify-center border-0 ring-0 focus:ring-0"
        :class="[
          validQueryString
            ? 'bg-[--blue-b-400] hover:bg-[--blue-b-200] focus:bg-[--blue-b-500] text-[--neutrals-white]'
            : 'bg-[--neutrals-white] hover:bg-[--neutrals-white] focus:bg-[--neutrals-white] text-[--blue-b-400]'
        ]"
        :disabled="!validQueryString ? 'disabled' : null"
      >
        <span class="sr-only">{{ searchLink.text }}</span>

        <MagnifyingGlassIcon />
      </button>
    </form>
  </div>
</template>

<script>
import MagnifyingGlassIcon from "@/components/icons/MagnifyingGlassIcon";

export default {
  name: "SearchForm",
  components: { MagnifyingGlassIcon },
  props: {
    searchLink: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      query: null
    };
  },
  computed: {
    validQueryString() {
      return this.query !== null && this.query.length >= 3;
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    search() {
      if (this.validQueryString) {
        window.location.href = `${this.searchLink.url}?q=${encodeURIComponent(
          this.query
        )}&type=all`;
      }
    },

    focusInput() {
      this.$refs.search_form.focus();
    }
  }
};
</script>
