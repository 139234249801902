<template>
  <div>
    <input
      v-model="fromDate"
      type="date"
    >
  </div>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch/vue3/es";
import connectStartDate from "./../services/connectStartDate";

export default {
  name: "StartDateFilter",
  mixins: [createWidgetMixin({ connector: connectStartDate })],
  data: () => ({
    fromDate: null
  }),
  watch: {
    fromDate: {
      immediate: false,
      handler() {
        const date = this.fromDate ? new Date(this.fromDate) : null;
        return this.onDateChange(date);
      }
    }
  },
  methods: {
    onDateChange(fromDate) {
      this.state.refine({
        from: fromDate
      });
    }
  }
};
</script>
