<template>
  <Splide
    v-if="!loading"
    :key="id"
    :options="options" 
    :has-track="false"
    :class="`${ overflowVisible ? '!overflow-visible' :''}`"
    @splide:resize="handleResize"
  >
    <!-- Slides -->
    <SplideTrack :class="`${ overflowVisible ? '!overflow-visible' :''}`">
      <SplideSlide 
        v-for="index in slideCount" 
        :key="index"
        :class="`${ overflowVisible ? '!overflow-visible' :''}`"
      >
        <slot :name="`slide--${index}`" />
      </SplideSlide>
    </SplideTrack>


    <ul
      class="splide__pagination"
      :class="{ 'hidden': !options.pagination }"
    />

    <div
      class="splide__progress"
      :class="{ 'hidden': !options.progress }"
    >
      <div class="splide__progress__bar" />
    </div>

    <div
      class="splide__arrows"
      :class="{ 'hidden': !options.arrows }"
    >
      <button class="splide__arrow splide__arrow--prev">
        Prev
      </button>
      <button class="splide__arrow splide__arrow--next">
        Next
      </button>
    </div>
  </Splide>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";

export default {
  name: "TwCarouselDynamicSize",
  components: { 
    Splide, 
    SplideSlide,
    SplideTrack
  },
  props: {
    slideCount: {
      type: Number,
      required: true
    },
    overflowVisible:{
      type: Boolean,
      default: false,
    },
    showArrows:{
      type: Boolean,
      default: false,
    },
    showProgress:{
      type: Boolean,
      default: false,
    },
    settings:{
      type: Object,
      default: () => { return {} }
    },
  },
  data() {
    return {
      id: 0,
      options: {
        type: "slide",
        gap: "24px",
        autoWidth: true,
        pagination: false,
        arrows: true,
        trimSpace: "move",
        focus: "center",
        start: 0,
        omitEnd: false,
        breakpoints: {
          768: {
            start: 0,
            trimSpace: "move",
            focus: "center",
            gap: "12px",
          },
        },
      }
    }
  },
  beforeMount () {
    this.options = {
      ...this.options,
      ...this.settings,
    }
  }, 
  methods: {
    handleResize( ){
      this.id = this.id + 1;
    }
  },
 
}
</script>