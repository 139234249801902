<template>
  <div>
    <h2 class="quiz__results-calc-title">
      {{ copy.resultsCalculationsTitle }}
    </h2>

    <ul class="quiz__results-data-visualisations">
      <li
        v-for="(score, key) in results.scores"
        :key="key"
        class="quiz__results-chart"
      >
        <h3 class="quiz__results-category-title">
          {{ key }}
        </h3>

        <pie-chart-donut :initial-values="[score]" />
      </li>
    </ul>
  </div>
</template>

<script>
import PieChartDonut from "./PieChartDonut.vue";

export default {
  name: "TheQuizResults",
  components: {
    PieChartDonut
  },
  props: {
    copy: {
      required: true,
      type: Object
    },
    results: {
      required: true,
      type: Object
    }
  }
};
</script>
