<template>
  <div
    v-if="open"
    @click="handleClickModal"
  >
    <slot :close-modal="closeModal" />
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  mounted() {
    if (this.isOpen) this.openModal();
  },
  methods: {
    openModal() {
      this.open = true;
    },
    closeModal() {
      this.open = false;
    },
    handleClickModal(event) {
      const elementCloseList = ["c-modal", "c-modal__close"];
      if (elementCloseList.some(item => [...event.target.classList].includes(item))) {
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss">
.c-modal {
  animation: fadeIn 0.4s ease 0s 1;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 12;
  width: 100%;
  display: flex;
  justify-content: center;
}
.c-modal__content {
  background-color: #0f5384;
  color: white;
  padding: 30px;
  width: 90%;
  height: 300px;
  top: 30%;
  position: relative;

  @media only screen and (min-width: 768px) {
    width: 50%;
    height: 330px;
    top: 35%;
  }
}
.c-modal__title {
  padding-bottom: 25px;
}

.c-modal__description {
  height: 130px;
  overflow: scroll;
}

.c-modal__close {
  cursor: pointer;
  z-index: 16;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  margin-right: 20px;
  margin-top: 20px;
  opacity: 0.7;
  background: transparent;
  border: none;
  font-size: 30px;
  &:hover {
    opacity: 1;
  }
}
</style>
