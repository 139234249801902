export const getCookieExpiryTime = () => {
  const now = new Date();
  now.setTime(now.getTime() + (1 * 60 * 60 * 1000)); // 1 hour in milliseconds
  return now.toUTCString();
};

export const setCookie = (name, value, expiryTime, path = "/") => {
  document.cookie = `${name}=${value}; expires=${expiryTime}; path=${path}`;
};

export const clearCookiesArray = (cookiesArray = []) => {
  const expiryTime = new Date(0).toUTCString(); // Set the expiry date to the past
  cookiesArray.forEach(cookie => {
    setCookie(cookie, "", expiryTime);
  });
};

// // Function to handle training date selection update
const updateTrainingDateSelection = () => {
  const selectionFields = document.querySelectorAll(".training-selection__training-select");
  const trainingStartDate = Array.from(selectionFields).map(e => e.value);
  
  const offerteField = document.querySelector(".offerte-form input[name='trainingStartDate']");
  if (offerteField) {
      offerteField.value = trainingStartDate.join(", ");
      console.log( offerteField.value )
  }
}

// Function to validate the form
const validateForm = (event) => {
  event.preventDefault(); // Prevent the form from submitting
  const errorContainer = document.querySelector('.training-selection__error');
  const selectElements = Array.from(document.querySelectorAll('.training-selection__training-select'));
  const signUpButton = document.querySelector("button[data-signup-link]");
  const signUpString = signUpButton ? signUpButton.getAttribute("data-signup-link") : "inschrijven";
  const signUpTrainingIds = signUpButton ? signUpButton.getAttribute("data-trainingids") : null;
  const signUpPageId = signUpButton ? signUpButton.getAttribute("data-pageid") : null;

  if (selectElements.length === 0 || !signUpButton) {
    return;
  }

  const validTrainingDateSpacing = true; // Replace with actual validation logic if needed
  errorContainer.style.display = !validTrainingDateSpacing ? "flex" : "none";

  if (validTrainingDateSpacing) {
    const expiryTime = getCookieExpiryTime();
    const compoundSelectionString = JSON.stringify(selectElements.map((select) => {
      const selection = select.value;
      const match = selection.match(/\(([^)]+)\)/);
      return match ? match[1] : selection
    } ));

    if (compoundSelectionString && signUpTrainingIds && signUpPageId) {
      setCookie("training-growth__trainingRunIds", compoundSelectionString, expiryTime);
      setCookie("training-growth__trainingIds", signUpTrainingIds, expiryTime);
      setCookie("training-growth__pageId", signUpPageId, expiryTime);
      window.location.href = `${window.location.pathname}/${signUpString}`;
    }
  }
};

// Attach the functions to the window object
window.validateForm = validateForm;
window.updateTrainingDateSelection = updateTrainingDateSelection;