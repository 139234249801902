/**
 * @fileoverview Custom Algolia Search module for the StartDateFilter component
 */

const createRefine =
  ({ helper }) =>
  ({ from }) => {
    if (from && !isNaN(from)) {
      // Reset the state, otherwise the search instance will accumulate
      // all previews selected dates; not letting the user go back to
      // any previous dates
      helper.clearRefinements(["date_timestamp"]);

      const lowerBound = from.getTime() / 1000;

      // Filters all results before the provided date, only showing future entries
      helper.addNumericRefinement("date_timestamp", ">=", lowerBound);
    }

    helper.search();
  };

const connectStartDate =
  render =>
  (widgetParams = {}) => {
    const { from } = widgetParams;
    return {
      init({ instantSearchInstance, helper }) {
        render(
          {
            instantSearchInstance,
            refine: createRefine({
              helper,
              attributes: { from }
            })
          },
          true
        );
      },
      render({ instantSearchInstance, helper }) {
        render(
          {
            instantSearchInstance,
            refine: createRefine({
              helper,
              attributes: { from }
            })
          },
          false
        );
      }
    };
  };

export default connectStartDate;
