<template>
  <div class="col-start-2 col-span-12 grid gap-8 md:grid-cols-2 lg:hidden">
    <Disclosure
      v-for="(column, columnIndex) in navColumns"
      :key="`column--${columnIndex}`"
      v-slot="{ open }"
      as="div"
      class="w-full flex flex-col md:col-span-1"
    >
      <DisclosureButton class-name="px-0 flex items-center justify-between w-full">
        <div class="flex flex-col w-full py-0">
          <div class="flex flex-row gap-2 items-center justify-between border-0 text-[--neutrals-white] w-full">
            <p class="text-base font-medium uppercase">
              {{ column.title }}
            </p>

            <ChevronDownIcon :class="['transition-transform', {'rotate-180': open, 'rotate-0': !open}]" />
          </div>
          <span class="tw-divider w-full" />
        </div>
      </DisclosureButton>

      <DisclosurePanel class-name="flex flex-col gap-6">
        <div class="flex flex-col gap-6 px-0">
          <ul
            role="list"
            class="flex flex-col gap-4"
          >
            <li 
              v-for="(link, linkIndex) in [...column.col1, ...column.col2]" 
              :key="`col__item--${linkIndex}`" 
              class="flex"
            >
              <a
                :href="link.url"
                :target="link.target"
                class="group text-sm flex gap-2 items-center text-[--neutrals-white] hover:text-[--neutrals-white] focus:text-[--neutrals-white] "
              >
                {{ link.title }}
              </a>
            </li>
          </ul>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon";

export default {
  name: "TwFooter",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon
  },
  props: {
    navColumns: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
