<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path d="M20.823 15v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4M6.823 10l5 5 5-5M11.823 15V3" />
    </g>
  </svg>
</template>
