<template>
  {{ formatedText }}
</template>
  
  <script>
  export default {
    name: "RenderNumber",
    props: {
      text: {
        type: [String, Number],
        required: true
      },
      isMoney: {
        type: Boolean,
        default: true
      }
    },
    computed: {
        formatedText() {
          if(isNaN(this.text) ) { return this.text}
          const text = typeof this.text === 'number' ? this.text.toString() : this.text;
          const numberString = text.replace(/\s/g, '').replace(/€/g, '');

          const [integerPart, decimalPart] = numberString.split('.');
          const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          const formattedNumber = decimalPart ? `${formattedIntegerPart},${this.formatDecimalNumber(decimalPart)}` : `${formattedIntegerPart} ${this.isMoney ? ",-" : ""}`;
          return formattedNumber;
        }
    },
    methods:{
      formatDecimalNumber(numberString){
        if (numberString === "") { return numberString; }
        if( numberString.length === 1) { return `${numberString}0`; }
        return numberString.substring(0, 2);
      }
    }
  };
  </script>
  