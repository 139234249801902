<!-- Original source: https://css-tricks.com/building-a-donut-chart-with-vue-and-svg/ -->

<template>
  <div class="pie-chart-donut">
    <svg
      height="80"
      width="80"
      viewBox="0 0 160 160"
    >
      <g
        v-for="(value, index) in sortedValues"
        :key="`stroke-${index}`"
      >
        <circle
          v-if="singleDatapoint"
          :cx="cx"
          :cy="cy"
          :r="radius"
          :stroke="'#D7E9E8'"
          :stroke-width="strokeWidth"
          fill="transparent"
        />
        <circle
          :cx="cx"
          :cy="cy"
          :r="radius"
          :stroke="colors[index]"
          :stroke-width="strokeWidth"
          :stroke-dasharray="adjustedCircumference"
          :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)"
          :transform="returnCircleTransformValue(index)"
          :stroke-linecap="singleDatapoint ? 'round' : ''"
          fill="transparent"
        />
        <text
          v-if="segmentBigEnough(value) && !singleDatapoint"
          text-anchor="middle"
          dy="3px"
          :x="chartData[index].textX"
          :y="chartData[index].textY"
        >
          {{ percentageLabel(value) }}
        </text>
      </g>
    </svg>

    <label
      v-if="singleDatapoint"
      class="pie-chart-donut__percentage-label"
    >
      {{ percentageLabel(sortedValues[0]) }}
    </label>
  </div>
</template>

<script>
export default {
  name: "PieChartDonut",
  props: {
    initialValues: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      angleOffset: -90,
      chartData: [],
      colors: [
        "rgb(47, 142, 210)",
        "rgba(223, 163, 14, 1)",
        "rgba(177, 197, 204, 0.6)",
        "rgb(176, 0, 32)",
        "rgb(89, 96, 113)"
      ],
      cx: 80,
      cy: 80,
      radius: 70,
      sortedValues: [],
      strokeWidth: 15
    };
  },
  computed: {
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    dataTotal() {
      return this.sortedValues.reduce((acc, val) => acc + val);
    },
    adjustedCircumference() {
      return this.circumference - 2;
    },
    singleDatapoint() {
      return this.initialValues.length < 2;
    }
  },
  mounted() {
    this.sortInitialValues();

    this.calculateChartData();
  },
  methods: {
    sortInitialValues() {
      const initialValues = this.initialValues;
      return (this.sortedValues = initialValues.sort((a, b) => b - a));
    },
    calculateChartData() {
      this.sortedValues.forEach(dataVal => {
        const { x, y } = this.calculateTextCoords(dataVal, this.angleOffset);
        const data = {
          degrees: this.angleOffset,
          textX: x,
          textY: y
        };
        this.chartData.push(data);
        this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset;
      });
    },
    dataPercentage(dataVal) {
      return this.singleDatapoint ? dataVal / 100 : dataVal / this.dataTotal;
    },
    calculateStrokeDashOffset(dataVal, circumference) {
      const strokeDiff = this.dataPercentage(dataVal) * circumference;
      return circumference - strokeDiff;
    },
    returnCircleTransformValue(index) {
      return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
    },
    degreesToRadians(angle) {
      return angle * (Math.PI / 180);
    },
    calculateTextCoords(dataVal, angleOffset) {
      const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset;
      const radians = this.degreesToRadians(angle);

      const textCoords = {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy
      };
      return textCoords;
    },
    percentageLabel(dataVal) {
      return `${Math.round(this.dataPercentage(dataVal) * 100)}%`;
    },
    segmentBigEnough(dataVal) {
      return Math.round(this.dataPercentage(dataVal) * 100) > 5;
    }
  }
};
</script>
