<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="-1 0 24 24"
    fill="currentColor"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.988 12.297c0-.646-.448-1.032-1.24-1.032-.323 0-.542.031-.657.063v2.075c.136.03.302.041.531.041.845 0 1.366-.427 1.366-1.147ZM10.89 11.286c-.355 0-.584.031-.72.063v4.598c.136.031.355.031.553.031 1.439.01 2.377-.781 2.377-2.46.01-1.46-.845-2.232-2.21-2.232Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.282 8.585h-.576V5.804c0-.017-.003-.035-.005-.052a.462.462 0 0 0-.112-.305L14.962.16l-.004-.003a.468.468 0 0 0-.094-.079l-.03-.018a.485.485 0 0 0-.093-.04l-.025-.008A.469.469 0 0 0 14.607 0H3.235a.942.942 0 0 0-.94.941v7.643h-.577c-.743 0-1.345.603-1.345 1.346v6.995c0 .743.602 1.345 1.345 1.345h.576v4.789c0 .519.422.941.941.941h15.53a.942.942 0 0 0 .94-.941V18.27h.577c.743 0 1.345-.602 1.345-1.345V9.93c0-.743-.602-1.345-1.345-1.345ZM3.235.94h10.902v4.816c0 .26.21.47.47.47h4.158v2.358H3.235V.941ZM14.79 13.465c0 1.345-.49 2.273-1.169 2.847-.739.615-1.865.907-3.241.907a13.82 13.82 0 0 1-1.804-.104v-6.903a14.21 14.21 0 0 1 2.148-.146c1.334 0 2.2.24 2.878.75.73.543 1.188 1.408 1.188 2.649Zm-12.273 3.68v-6.933c.49-.083 1.179-.146 2.148-.146.98 0 1.68.188 2.148.563.449.355.75.939.75 1.627 0 .688-.229 1.272-.646 1.668-.542.511-1.345.74-2.283.74-.209 0-.397-.01-.542-.03v2.512H2.516Zm16.249 5.659H3.235V18.27h15.53v4.534Zm1.363-11.382h-2.7v1.605h2.523v1.293h-2.524v2.826h-1.594v-7.028h4.295v1.304Z"
      />
    </g>
  </svg>
</template>
