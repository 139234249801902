<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 3.75C14.9175 3.4875 14.0025 3.375 13.125 3.375C11.6625 3.375 10.0875 3.675 9 4.5C7.9125 3.675 6.3375 3.375 4.875 3.375C3.4125 3.375 1.8375 3.675 0.75 4.5V15.4875C0.75 15.675 0.9375 15.8625 1.125 15.8625C1.2 15.8625 1.2375 15.825 1.3125 15.825C2.325 15.3375 3.7875 15 4.875 15C6.3375 15 7.9125 15.3 9 16.125C10.0125 15.4875 11.85 15 13.125 15C14.3625 15 15.6375 15.225 16.6875 15.7875C16.7625 15.825 16.8 15.825 16.875 15.825C17.0625 15.825 17.25 15.6375 17.25 15.45V4.5C16.8 4.1625 16.3125 3.9375 15.75 3.75ZM15.75 13.875C14.925 13.6125 14.025 13.5 13.125 13.5C11.85 13.5 10.0125 13.9875 9 14.625V6C10.0125 5.3625 11.85 4.875 13.125 4.875C14.025 4.875 14.925 4.9875 15.75 5.25V13.875Z"
      :fill="fill"
    />
    <path
      d="M13.125 7.875C13.785 7.875 14.4225 7.9425 15 8.07V6.93C14.4075 6.8175 13.77 6.75 13.125 6.75C11.85 6.75 10.695 6.9675 9.75 7.3725V8.6175C10.5975 8.1375 11.775 7.875 13.125 7.875Z"
      :fill="fill"
    />
    <path
      d="M9.75 9.36762V10.6126C10.5975 10.1326 11.775 9.87012 13.125 9.87012C13.785 9.87012 14.4225 9.93762 15 10.0651V8.92512C14.4075 8.81262 13.77 8.74512 13.125 8.74512C11.85 8.74512 10.695 8.97012 9.75 9.36762Z"
      :fill="fill"
    />
    <path
      d="M13.125 10.7476C11.85 10.7476 10.695 10.9651 9.75 11.3701V12.6151C10.5975 12.1351 11.775 11.8726 13.125 11.8726C13.785 11.8726 14.4225 11.9401 15 12.0676V10.9276C14.4075 10.8076 13.77 10.7476 13.125 10.7476Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "BookIcon",
  props: {
    fill: {
      type: String,
      default: "#000"
    }
  }
};
</script>
